// Add this file as GlobalAudioStateIntegration.js in the same directory as your visualizer components

/**
 * GlobalAudioStateIntegration.js - Improved audio analyzer integration
 * 
 * This file provides robust audio analyzer integration for visualizers
 * with fallbacks and better error handling.
 */

import globalAudioState from './globalAudioState';

// Global tracking of audio analyzer connections to prevent duplicate connections
if (typeof window !== 'undefined' && !window._audioAnalyzerTracker) {
  window._audioAnalyzerTracker = {
    connectedElements: new WeakMap(),
    analyzers: [],
    audioContexts: [],
    lastCreationTime: null,
    connectionAttempts: 0,
    errors: []
  };
}

/**
 * Creates a robust audio analyzer with fallback mechanisms
 */
export const createRobustAnalyzer = (options = {}) => {
  const {
    audioElement = null,
    fallbackOnly = false,
    onSuccess = null,
    onError = null
  } = options;
  
  // Use provided audio element or get from global state
  let targetAudioElement = audioElement || 
                          (globalAudioState && globalAudioState._audioElement) || 
                          (window.globalAudioState && window.globalAudioState._audioElement);

  console.log("Creating robust analyzer, audio element available:", !!targetAudioElement);
  
  // Return existing global analyzer if available
  if (!fallbackOnly && window.globalAudioState && window.globalAudioState._analyzer) {
    try {
      const existingAnalyzer = window.globalAudioState._analyzer;
      if (typeof existingAnalyzer.getByteFrequencyData === 'function') {
        console.log("Using existing global analyzer");
        if (onSuccess) onSuccess(existingAnalyzer);
        return existingAnalyzer;
      }
    } catch (err) {
      console.warn("Existing global analyzer is invalid:", err);
    }
  }
  
  // Create fallback audio element if needed
  if (!targetAudioElement) {
    console.log("No audio element available, creating fallback");
    try {
      const silentAudio = new Audio();
      silentAudio.crossOrigin = "anonymous";
      let silentUrl = "data:audio/mpeg;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU4LjI5LjEwMAAAAAAAAAAAAAAA//tQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAWGluZwAAAA8AAAACAAADmAD///////////////////////////////////////////8AAAA5TEFNRTMuMTAwAc0AAAAAAAAAABSAJAJAQgAAgAAAA5iJWtsAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//sQZAAP8AAAaQAAAAgAAA0gAAABAAABpAAAACAAADSAAAAETEFNRTMuMTAwVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV//sQZB4P8AAAaQAAAAgAAA0gAAABAAABpAAAACAAADSAAAAEVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";
      
      if (globalAudioState && typeof globalAudioState.getSilentAudioUrl === 'function') {
        silentUrl = globalAudioState.getSilentAudioUrl();
      }
      
      silentAudio.src = silentUrl;
      silentAudio.load();
      targetAudioElement = silentAudio;
      
      // Store for reuse
      if (globalAudioState && !globalAudioState._audioElement) {
        globalAudioState._audioElement = silentAudio;
      }
      
      if (window.globalAudioState && !window.globalAudioState._audioElement) {
        window.globalAudioState._audioElement = silentAudio;
      }
    } catch (err) {
      console.error("Failed to create silent audio element:", err);
      return createFallbackAnalyzer(onSuccess, onError);
    }
  }
  
  try {
    // Create AudioContext
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!AudioContext) {
      console.warn("AudioContext not supported in this browser");
      return createFallbackAnalyzer(onSuccess, onError);
    }
    
    // Use existing AudioContext if available
    let audioContext = null;
    
    if (window.globalAudioState && window.globalAudioState._audioContext) {
      audioContext = window.globalAudioState._audioContext;
      console.log("Using existing global AudioContext");
    } else if (globalAudioState && globalAudioState._audioContext) {
      audioContext = globalAudioState._audioContext;
      console.log("Using AudioContext from globalAudioState");
    } else if (window._audioAnalyzerTracker.audioContexts.length > 0) {
      // Use the most recent context that isn't closed
      for (let i = window._audioAnalyzerTracker.audioContexts.length - 1; i >= 0; i--) {
        const ctx = window._audioAnalyzerTracker.audioContexts[i];
        if (ctx && ctx.state !== 'closed') {
          audioContext = ctx;
          console.log("Using previously created AudioContext");
          break;
        }
      }
    }
    
    // Create new AudioContext if needed
    if (!audioContext) {
      audioContext = new AudioContext();
      console.log("Created new AudioContext");
      window._audioAnalyzerTracker.audioContexts.push(audioContext);
      
      // Store in global state
      if (globalAudioState) {
        globalAudioState._audioContext = audioContext;
      }
      
      if (window.globalAudioState) {
        window.globalAudioState._audioContext = audioContext;
      }
    }
    
    // Create analyzer node
    const analyzer = audioContext.createAnalyser();
    analyzer.fftSize = 2048;
    analyzer.smoothingTimeConstant = 0.85;
    analyzer.minDecibels = -90;
    analyzer.maxDecibels = -10;
    
    // Create data arrays for visualization
    analyzer.frequencyDataArray = new Uint8Array(analyzer.frequencyBinCount);
    analyzer.timeDataArray = new Uint8Array(analyzer.frequencyBinCount);
    
    // Track current attempt
    window._audioAnalyzerTracker.connectionAttempts++;
    window._audioAnalyzerTracker.lastCreationTime = Date.now();
    
    // Try connecting to the audio element
    let connected = false;
    let source = null;
    
    // First check if the element is already connected
    if (window._audioAnalyzerTracker.connectedElements.has(targetAudioElement)) {
      try {
        source = window._audioAnalyzerTracker.connectedElements.get(targetAudioElement);
        source.connect(analyzer);
        analyzer.connect(audioContext.destination);
        connected = true;
        console.log("Using existing audio element connection");
      } catch (err) {
        console.warn("Error reusing existing connection:", err);
        window._audioAnalyzerTracker.connectedElements.delete(targetAudioElement);
      }
    }
    
    // If not already connected, create a new connection
    if (!connected) {
      try {
        source = audioContext.createMediaElementSource(targetAudioElement);
        source.connect(analyzer);
        analyzer.connect(audioContext.destination);
        source.connect(audioContext.destination); // Ensure audio still plays
        
        // Track this connection
        window._audioAnalyzerTracker.connectedElements.set(targetAudioElement, source);
        connected = true;
        
        console.log("Successfully connected audio element to analyzer");
      } catch (err) {
        // Don't treat "already connected" as fatal
        if (err.message && err.message.includes('already connected')) {
          console.warn("Audio element already connected (expected):", err.message);
          window._audioAnalyzerTracker.errors.push({
            type: 'already_connected',
            message: err.message,
            time: Date.now()
          });
          
          // Try creating oscillator fallback while keeping audio playback
          try {
            const oscillator = audioContext.createOscillator();
            oscillator.frequency.value = 0; // Silent
            const gain = audioContext.createGain();
            gain.gain.value = 0; // Silent
            
            oscillator.connect(gain);
            gain.connect(analyzer);
            analyzer.connect(audioContext.destination);
            oscillator.start();
            
            // Store cleanup function
            analyzer._cleanup = () => {
              try {
                oscillator.stop();
                oscillator.disconnect();
                gain.disconnect();
              } catch (e) {
                // Ignore cleanup errors
              }
            };
            
            connected = true;
            console.log("Created silent oscillator fallback for visualization");
          } catch (oscErr) {
            console.warn("Error creating oscillator fallback:", oscErr);
          }
        } else {
          // For other errors, log and continue to fallback
          console.error("Error connecting audio element:", err);
          window._audioAnalyzerTracker.errors.push({
            type: 'connection_error',
            message: err.message,
            time: Date.now()
          });
        }
      }
    }
    
    // If all connection attempts failed, create a standalone fallback
    if (!connected) {
      try {
        console.log("All connection attempts failed, creating standalone fallback");
        
        // Create a buffer source as fallback
        const bufferSize = audioContext.sampleRate * 2; // 2 seconds
        const buffer = audioContext.createBuffer(2, bufferSize, audioContext.sampleRate);
        
        // Fill with silence
        for (let channel = 0; channel < buffer.numberOfChannels; channel++) {
          const channelData = buffer.getChannelData(channel);
          for (let i = 0; i < channelData.length; i++) {
            channelData[i] = 0;
          }
        }
        
        const bufferSource = audioContext.createBufferSource();
        bufferSource.buffer = buffer;
        bufferSource.loop = true;
        
        bufferSource.connect(analyzer);
        analyzer.connect(audioContext.destination);
        bufferSource.start();
        
        // Store cleanup function
        analyzer._cleanup = () => {
          try {
            bufferSource.stop();
            bufferSource.disconnect();
          } catch (e) {
            // Ignore cleanup errors
          }
        };
        
        // Add data generation for visualization
        addFallbackDataGeneration(analyzer, targetAudioElement);
        
        connected = true;
      } catch (bufferErr) {
        console.error("Error creating buffer source fallback:", bufferErr);
      }
    }
    
    // If we successfully created an analyzer, store it and return
    if (connected) {
      window._audioAnalyzerTracker.analyzers.push(analyzer);
      
      // Store in global state for reuse
      if (globalAudioState) {
        globalAudioState._analyzer = analyzer;
      }
      
      if (window.globalAudioState) {
        window.globalAudioState._analyzer = analyzer;
      }
      
      // Resume context if suspended
      if (audioContext.state === 'suspended') {
        audioContext.resume().catch(err => {
          console.warn("Error resuming audio context:", err);
        });
      }
      
      if (onSuccess) onSuccess(analyzer);
      return analyzer;
    }
    
    // If we reach here, all attempts failed
    return createFallbackAnalyzer(onSuccess, onError);
  } catch (error) {
    console.error("Error creating audio analyzer:", error);
    window._audioAnalyzerTracker.errors.push({
      type: 'creation_error',
      message: error.message,
      stack: error.stack,
      time: Date.now()
    });
    
    if (onError) onError(error);
    return createFallbackAnalyzer(onSuccess, onError);
  }
};

/**
 * Creates a standalone fallback analyzer that simulates audio data
 */
function createFallbackAnalyzer(onSuccess, onError) {
  console.log("Creating standalone fallback analyzer");
  
  try {
    // Create a mock analyzer object
    const mockAnalyzer = {
      fftSize: 2048,
      frequencyBinCount: 1024,
      smoothingTimeConstant: 0.8,
      minDecibels: -100,
      maxDecibels: -30,
      
      // Pre-allocate data arrays
      frequencyDataArray: new Uint8Array(1024),
      timeDataArray: new Uint8Array(1024),
      
      // Implement the visualization data methods
      getByteFrequencyData: function(dataArray) {
        const time = Date.now() / 1000;
        
        for (let i = 0; i < dataArray.length; i++) {
          // Create a falling frequency curve (more bass, less treble)
          const normalizedIndex = i / dataArray.length;
          const baseCurve = 120 * Math.pow(1 - normalizedIndex, 1.4);
          
          // Add time-based modulation
          const mod1 = 30 * Math.sin(time * 2 + i * 0.05);
          const mod2 = 15 * Math.sin(time * 0.7 + i * 0.15);
          const mod3 = 10 * Math.sin(time * 0.5 + i * 0.3);
          
          // Add some randomness
          const noise = Math.random() * 5;
          
          // Combine all factors
          const value = baseCurve + mod1 + mod2 + mod3 + noise;
          
          // Ensure value is within 0-255 range
          dataArray[i] = Math.max(0, Math.min(255, value));
        }
      },
      
      getByteTimeDomainData: function(dataArray) {
        const time = Date.now() / 1000;
        
        for (let i = 0; i < dataArray.length; i++) {
          // Create a sine wave with some modulation
          const phase = (i / dataArray.length) * Math.PI * 2;
          const base = 128; // Center point
          const primaryWave = 40 * Math.sin(time * 2 + phase);
          const secondaryWave = 20 * Math.sin(time * 4 + phase * 3);
          const tertiaryWave = 10 * Math.sin(time * 7 + phase * 7);
          
          // Combine waves and ensure value is within 0-255 range
          const value = base + primaryWave + secondaryWave + tertiaryWave;
          dataArray[i] = Math.max(0, Math.min(255, value));
        }
      }
    };
    
    // Track this fallback
    if (window._audioAnalyzerTracker) {
      window._audioAnalyzerTracker.analyzers.push(mockAnalyzer);
    }
    
    // Store in global state for reuse
    if (window.globalAudioState) {
      window.globalAudioState._analyzer = mockAnalyzer;
    }
    
    if (globalAudioState) {
      globalAudioState._analyzer = mockAnalyzer;
    }
    
    if (onSuccess) onSuccess(mockAnalyzer);
    return mockAnalyzer;
  } catch (error) {
    console.error("Error creating fallback analyzer:", error);
    
    if (onError) onError(error);
    
    // Return a minimal fallback
    return {
      getByteFrequencyData: function() {},
      getByteTimeDomainData: function() {}
    };
  }
}

/**
 * Adds data generation capabilities to an analyzer node
 */
function addFallbackDataGeneration(analyzer, audioElement) {
  if (!analyzer) return;
  
  // Store original methods
  const originalGetByteFrequencyData = analyzer.getByteFrequencyData;
  const originalGetByteTimeDomainData = analyzer.getByteTimeDomainData;
  
  // Override frequency data method
  analyzer.getByteFrequencyData = function(dataArray) {
    // Call original first
    originalGetByteFrequencyData.call(this, dataArray);
    
    // Check if we got any significant data
    let hasData = false;
    for (let i = 0; i < dataArray.length; i += 10) {
      if (dataArray[i] > 5) {
        hasData = true;
        break;
      }
    }
    
    // If no real data, generate visualization data
    if (!hasData) {
      const time = Date.now() / 1000;
      const isPlaying = audioElement && !audioElement.paused;
      
      for (let i = 0; i < dataArray.length; i++) {
        // Create frequency distribution (higher values for lower frequencies)
        const normalizedIndex = i / dataArray.length;
        const baseCurve = isPlaying ? 
          100 * Math.pow(1 - normalizedIndex, 1.4) : 
          40 * Math.pow(1 - normalizedIndex, 1.2);
        
        // Add time-based modulation
        const modulation = isPlaying ? 
          30 * Math.sin(time * 2 + i * 0.05) + 
          15 * Math.sin(time * 0.7 + i * 0.15) + 
          10 * Math.sin(time * 0.5 + i * 0.3) : 
          10 * Math.sin(time * 0.5 + i * 0.05) + 
          5 * Math.sin(time * 0.3 + i * 0.1);
        
        // Add some randomness
        const noise = isPlaying ? Math.random() * 10 : Math.random() * 3;
        
        // Combine all factors
        const value = baseCurve + modulation + noise;
        
        // Ensure value is within 0-255 range
        dataArray[i] = Math.max(0, Math.min(255, value));
      }
    }
  };
  
  // Override time domain data method
  analyzer.getByteTimeDomainData = function(dataArray) {
    // Call original first
    originalGetByteTimeDomainData.call(this, dataArray);
    
    // Check if we got varying data (not just 128)
    let hasVariation = false;
    for (let i = 0; i < dataArray.length; i += 10) {
      if (Math.abs(dataArray[i] - 128) > 5) {
        hasVariation = true;
        break;
      }
    }
    
    // If no variation, generate a waveform
    if (!hasVariation) {
      const time = Date.now() / 1000;
      const isPlaying = audioElement && !audioElement.paused;
      
      for (let i = 0; i < dataArray.length; i++) {
        // Create phase for sine wave
        const phase = (i / dataArray.length) * Math.PI * 2;
        
        // Center point
        const base = 128;
        
        // Primary wave with amplitude based on playback state
        const primaryAmplitude = isPlaying ? 40 : 15;
        const primaryWave = primaryAmplitude * Math.sin(time * 2 + phase);
        
        // Secondary waves for complexity
        const secondaryAmplitude = isPlaying ? 20 : 8;
        const secondaryWave = secondaryAmplitude * Math.sin(time * 4 + phase * 3);
        
        const tertiaryAmplitude = isPlaying ? 10 : 5;
        const tertiaryWave = tertiaryAmplitude * Math.sin(time * 7 + phase * 7);
        
        // Combine waves and ensure value is within 0-255 range
        const value = base + primaryWave + secondaryWave + tertiaryWave;
        dataArray[i] = Math.max(0, Math.min(255, value));
      }
    }
  };
}

/**
 * Initializes an analyzer node with proper integration to the global audio state
 */
export const initializeAnalyzerNode = (options = {}) => {
  const {
    audioElement = null,
    onInitialized = null,
    analyzerRef = null,
    setAnalyzerNode = null,
    setAudioConnected = null
  } = options;
  
  console.log("Initializing analyzer node integration");
  
  // Try to use existing global analyzer
  if (window.globalAudioState && window.globalAudioState._analyzer) {
    const existingAnalyzer = window.globalAudioState._analyzer;
    
    if (typeof existingAnalyzer.getByteFrequencyData === 'function') {
      console.log("Using existing global analyzer");
      
      if (analyzerRef) {
        analyzerRef.current = existingAnalyzer;
      }
      
      if (setAnalyzerNode) {
        setAnalyzerNode(existingAnalyzer);
      }
      
      if (setAudioConnected) {
        setAudioConnected(true);
      }
      
      if (onInitialized) {
        onInitialized(existingAnalyzer);
      }
      
      return true;
    }
  }
  
  // Create a new analyzer with robust handling
  createRobustAnalyzer({
    audioElement,
    onSuccess: (analyzer) => {
      console.log("Successfully created analyzer node");
      
      // Update references
      if (analyzerRef) {
        analyzerRef.current = analyzer;
      }
      
      if (setAnalyzerNode) {
        setAnalyzerNode(analyzer);
      }
      
      if (setAudioConnected) {
        setAudioConnected(true);
      }
      
      if (onInitialized) {
        onInitialized(analyzer);
      }
    },
    onError: (error) => {
      console.error("Error initializing analyzer:", error);
      
      // Create fallback analyzer
      const fallbackAnalyzer = createFallbackAnalyzer();
      
      // Update references
      if (analyzerRef) {
        analyzerRef.current = fallbackAnalyzer;
      }
      
      if (setAnalyzerNode) {
        setAnalyzerNode(fallbackAnalyzer);
      }
      
      if (setAudioConnected) {
        setAudioConnected(true); // Pretend we're connected
      }
      
      if (onInitialized) {
        onInitialized(fallbackAnalyzer);
      }
    }
  });
  
  return true;
};

export default {
  createRobustAnalyzer,
  initializeAnalyzerNode
};


