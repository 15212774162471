import React, { useState, useEffect, useCallback, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js';
import debounce from 'lodash.debounce';

// Import centralized audio state management
import globalAudioState from '../audio/globalAudioState';

// Import audio sync utilities
import { 
  syncPlayback, 
  syncTimePosition, 
  safeDestroyWaveSurfer, 
  safeCreateWaveSurfer,
  prepareFullscreenTransition,
  checkTransitionState,
  initializeAudio as initAudioFromUtils
} from '../audio/audio-sync-fix';

// Import robust audio analysis tools
import { 
  createRobustAnalyzer, 
  initializeAnalyzerNode 
} from '../audio/GlobalAudioStateIntegration';

// Import visualizer components
import {
  FullScreenVisualizer,
  VisualizerLoader
} from '../audio/Visualizers';

// Import UI icons
import {
  IoPlayCircle,
  IoPauseCircle,
  IoPlaySkipBack,
  IoPlaySkipForward,
  IoList,
  IoClose,
  IoDownload,
  IoRefresh,
  IoImage,
  IoExpand,
  IoContract,
  IoVolumeHigh,
  IoVolumeOff
} from 'react-icons/io5';

const ListenStep = ({
  audioUrl: initialAudioUrl,
  onExportAudio,
  onReset,
  onError,
  toast,
  selectedMusic = "",
  musicLibrary = [],
  setSelectedMusic = () => {},
  onApplyChanges = () => {},
  baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site',
  isMixingAudio = false,
  onAudioLoaded = null,
  ttsKey = "",
  windowDimensions,
  mixedAudioInfo = null,
  getBestAudioUrl = null,
  centralizeAudioUrl = null,
  setIsMixingAudio = () => {}
}) => {
  // --- State Declarations ---
  const [audioUrl, setAudioUrl] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [isLoadingAudio, setIsLoadingAudio] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [useVisualizer, setUseVisualizer] = useState(true);
  const [visualizerSize, setVisualizerSize] = useState(300);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth || 0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight || 0);
  const [zoom, setZoom] = useState(50);
  const [volume, setVolume] = useState(1.0);
  const [isMuted, setIsMuted] = useState(false);
  const [wavesurferInitialized, setWavesurferInitialized] = useState(false);
  const [analyzerNode, setAnalyzerNode] = useState(null);
  const [componentInitialized, setComponentInitialized] = useState(false);
  const [playerReady, setPlayerReady] = useState(false); // Track when player is fully ready
  
  // --- Refs ---
  const mounted = useRef(true);
  const isMountedRef = useRef(true);
  const waveformDataRef = useRef([]);
  const audioElementRef = useRef(null);
  const waveformContainerRef = useRef(null);
  const timelineContainerRef = useRef(null);
  const mobileWaveformContainerRef = useRef(null);
  const waveSurferRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyzerRef = useRef(null);
  const audioUrlRef = useRef(initialAudioUrl || '');
  const safeToastRef = useRef(null);
  const transitionHandledRef = useRef(false);
  const transitionCompleteRef = useRef(false);
  const abortControllersRef = useRef([]);
  const pendingTimeoutsRef = useRef([]);
  const analyzerInitializationInProgress = useRef(false);
  const initializationInProgress = useRef(false);
  const isDestroyingRef = useRef(false);
  const instanceId = useRef(`listen-${Date.now()}-${Math.floor(Math.random() * 1000)}`).current;
  const actionsBlockedRef = useRef(false); // Track if actions are being blocked (for debounce)
  
  // --- Device and Size Detection ---
  const isMobile = windowWidth <= 768;
  const isFullscreenMobile = isFullscreen && isMobile;
  const isTablet = windowWidth > 768 && windowWidth < 1024;
  const albumCoverSize = isMobile ? 280 : isTablet ? 320 : 380;
  
  // --- Utility functions ---
  
  // Validate URL - use globalAudioState's implementation
  const isValidUrl = useCallback((url) => {
    return globalAudioState.isValidUrl(url);
  }, []);

  // Format time display
  const formatTime = useCallback((timeInSeconds) => {
    if (isNaN(timeInSeconds) || timeInSeconds < 0) return '0:00';
    
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }, []);

  // Update player readiness state
  const updatePlayerReadiness = useCallback(() => {
    // Only consider player ready when all critical components are initialized
    const isReady = audioLoaded && 
                    !isLoadingAudio && 
                    audioElementRef.current && 
                    !initializationInProgress.current && 
                    !isDestroyingRef.current &&
                    mounted.current;
    
    // Avoid unnecessary re-renders
    if (isReady !== playerReady) {
      console.log("AUDIO PLAYER: Updating player readiness:", isReady);
      setPlayerReady(isReady);
    }
  }, [audioLoaded, isLoadingAudio, playerReady]);

  // Get track info for display
  const getTrackInfo = useCallback(() => {
    try {
      if (!selectedMusic || !Array.isArray(musicLibrary) || musicLibrary.length === 0) {
        return { title: 'Your Meditation', artist: 'Pause' };
      }
      
      const track = musicLibrary.find(t => t && typeof t === 'object' && t.name === selectedMusic);
      
      if (track) {
        return {
          title: track.displayName || track.name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
          artist: 'Pause Music'
        };
      } else {
        return {
          title: selectedMusic ? selectedMusic.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) : 'Your Meditation',
          artist: 'Pause'
        };
      }
    } catch (err) {
      console.error("AUDIO PLAYER: Error in getTrackInfo:", err);
      return { title: 'Meditation', artist: 'Pause' };
    }
  }, [selectedMusic, musicLibrary]);

  // Define trackInfo
  const trackInfo = getTrackInfo();

  // Store safeToast in ref for access in callbacks
  const safeToast = useCallback((type, message, options = {}) => {
    if (!mounted.current) return null;
    try {
      if (globalAudioState && typeof globalAudioState.publishEvent === 'function') {
        globalAudioState.publishEvent('toast', { 
          type, 
          message, 
          options, 
          source: instanceId, 
          timestamp: Date.now() 
        });
      }
      if (toast) {
        if (typeof toast[type] === 'function') {
          return toast[type](message, options);
        } else if (typeof toast === 'function') {
          return toast(message, { ...options, type });
        }
      }
    } catch (err) {
      console.log(`Error showing toast: ${err.message}`);
    }
    console.log(`Toast (${type}): ${message}`);
    return null;
  }, [toast, instanceId]);

  useEffect(() => {
    safeToastRef.current = safeToast;
  }, [safeToast]);

  // Handle audio ready state
  const handleAudioReady = useCallback((audioData = {}) => {
    if (!mounted.current) {
      console.log("AUDIO PLAYER: Component unmounted, ignoring audio ready callback");
      return;
    }
    
    console.log("AUDIO PLAYER: Audio ready with data:", audioData);
    
    try {
      // Update URL state if available
      if (audioData && audioData.url && isValidUrl(audioData.url)) {
        setAudioUrl(audioData.url);
        audioUrlRef.current = audioData.url;
        
        // Store in global audio state for other components to access
        if (globalAudioState && typeof globalAudioState.updateAllAudioUrls === 'function') {
          globalAudioState.updateAllAudioUrls(audioData.url, 'player-ready');
        }
      }
      
      // Set duration if available and valid
      if (audioData && typeof audioData.duration === 'number' && !isNaN(audioData.duration) && audioData.duration > 0) {
        setDuration(audioData.duration);
      }
      
      // Mark audio as loaded
      setAudioLoaded(true);
      setIsLoadingAudio(false);
      
      // Audio initialization complete - set player as ready
      updatePlayerReadiness();
      
      // Track which sources are ready in this session
      if (window.__readyAudioSources) {
        window.__readyAudioSources = [...new Set([
          ...(window.__readyAudioSources || []),
          audioData.url
        ])];
      } else {
        window.__readyAudioSources = [audioData.url];
      }
      
      // Call optional callback
      if (onAudioLoaded && typeof onAudioLoaded === 'function') {
        onAudioLoaded(audioData);
      }
      
      // Automatically start playback if transition flags indicate playback should be active
      const shouldAutoPlay = window.__fsTransitionPlaying === true;
      
      if (shouldAutoPlay && audioElementRef.current && !isPlaying) {
        // Use a short delay to ensure UI is ready
        setTimeout(() => {
          if (mounted.current && audioElementRef.current && !isPlaying) {
            console.log("AUDIO PLAYER: Auto-starting playback from transition state");
            togglePlayback();
          }
        }, 500);
      }
    } catch (error) {
      console.error("AUDIO PLAYER: Error in handleAudioReady:", error);
      
      // Ensure loading state is cleared even if there's an error
      setIsLoadingAudio(false);
      
      // Still try to mark audio as loaded so the player isn't stuck in loading state
      setAudioLoaded(true);
      
      if (safeToastRef.current) {
        safeToastRef.current('warning', 'Audio initialized with warnings. Playback may be affected.');
      }
    }
  }, [isValidUrl, onAudioLoaded, updatePlayerReadiness, isPlaying]);

  // Transform filters to backend format
  const transformFiltersForBackend = useCallback((filtersObj) => {
    if (!filtersObj) return {};
    
    const transformedFilters = {};
    
    // Handle stereoPan -> stereo_pan conversion
    if (filtersObj.stereoPan?.enabled) {
      transformedFilters.stereo_pan = {
        enabled: true,
        ...filtersObj.stereoPan,
        preset_key: filtersObj.stereoPan.preset?.toLowerCase().replace(/\s+/g, '_')
      };
    }
    
    // Handle reverb conversion
    if (filtersObj.reverb?.enabled) {
      transformedFilters.reverb = {
        enabled: true,
        ...filtersObj.reverb,
        preset_key: filtersObj.reverb.preset?.toLowerCase().replace(/\s+/g, '_')
      };
    }
    
    // Handle eq/equalizer conversion
    if (filtersObj.eq?.enabled) {
      transformedFilters.eq = {
        enabled: true,
        ...filtersObj.eq,
        preset_key: filtersObj.eq.preset?.toLowerCase().replace(/\s+/g, '_')
      };
    } else if (filtersObj.equalizer?.enabled) {
      transformedFilters.eq = {
        enabled: true,
        ...filtersObj.equalizer,
        preset_key: filtersObj.equalizer.preset?.toLowerCase().replace(/\s+/g, '_')
      };
    }
    
    return transformedFilters;
  }, []);

  // Create fallback audio element - simplified to use globalAudioState 
  const createFallbackAudioElement = useCallback((url) => {
    if (!mounted.current) return null;
    
    console.log("AUDIO PLAYER: Creating fallback audio element with URL:", url);
    
    try {
      // Check if globalAudioState has a shared audio element
      if (globalAudioState._audioElement) {
        const sharedAudio = globalAudioState._audioElement;
        
        // Update URL if needed
        if (url && isValidUrl(url) && sharedAudio.src !== url) {
          sharedAudio.src = url;
          sharedAudio.load();
        }
        
        // Set up event listeners if not already
        if (!sharedAudio._listenersInitialized) {
          setupAudioElementListeners(sharedAudio);
        }
        
        // Store the element in ref
        audioElementRef.current = sharedAudio;
        return sharedAudio;
      }
      
      // Create a new audio element
      const audioEl = new Audio();
      
      // Set properties
      audioEl.crossOrigin = "anonymous";
      audioEl.preload = "auto";
      audioEl.autoplay = false;
      
      // Set up event listeners
      setupAudioElementListeners(audioEl);
      
      // Set source if URL is valid
      if (url && isValidUrl(url)) {
        audioEl.src = url;
        audioEl.load();
      } else {
        console.warn("AUDIO PLAYER: Invalid URL provided to fallback audio element");
        // Try to get silent audio URL from globalAudioState
        if (globalAudioState && typeof globalAudioState.getSilentAudioUrl === 'function') {
          const silentUrl = globalAudioState.getSilentAudioUrl();
          audioEl.src = silentUrl;
          audioEl.load();
        } else {
          console.warn("AUDIO PLAYER: Unable to get silent audio URL from globalAudioState");
        }
      }
      
      // Store the element in ref and global state
      audioElementRef.current = audioEl;
      if (globalAudioState) {
        globalAudioState._audioElement = audioEl;
      }
      
      // Return the created element
      return audioEl;
    } catch (error) {
      console.error("AUDIO PLAYER: Error creating fallback audio element:", error);
      setIsLoadingAudio(false);
      if (safeToastRef.current) {
        safeToastRef.current('error', 'Unable to initialize audio player. Please refresh and try again.');
      }
      return null;
    }
  }, [isValidUrl]);

  // Define audio element listeners setup
  const setupAudioElementListeners = useCallback((audioEl) => {
    if (!audioEl || audioEl._listenersInitialized) return;
    
    audioEl.addEventListener('loadedmetadata', () => {
      if (!mounted.current) return;
      console.log("AUDIO PLAYER: Audio metadata loaded, duration:", audioEl.duration);
      setDuration(audioEl.duration || 0);
      setAudioLoaded(true);
      setIsLoadingAudio(false);
      if (onAudioLoaded) {
        onAudioLoaded({ url: audioEl.src, duration: audioEl.duration || 0 });
      }
      
      // Check player readiness
      updatePlayerReadiness();
    });
    
    audioEl.addEventListener('timeupdate', () => {
      if (!mounted.current) return;
      setCurrentTime(audioEl.currentTime || 0);
    });
    
    audioEl.addEventListener('play', () => {
      if (!mounted.current) return;
      console.log("AUDIO PLAYER: Audio element play event");
      setIsPlaying(true);
      if (globalAudioState) {
        globalAudioState.state.audioPlaying = true;
      }
    });
    
    audioEl.addEventListener('pause', () => {
      if (!mounted.current) return;
      console.log("AUDIO PLAYER: Audio element pause event");
      setIsPlaying(false);
      if (globalAudioState) {
        globalAudioState.state.audioPlaying = false;
      }
    });
    
    audioEl.addEventListener('ended', () => {
      if (!mounted.current) return;
      setIsPlaying(false);
      setCurrentTime(0);
      if (globalAudioState) {
        globalAudioState.state.audioPlaying = false;
      }
    });
    
    audioEl.addEventListener('error', (error) => {
      console.error("AUDIO PLAYER: Audio element error:", error);
      
      if (mounted.current) {
        setIsPlaying(false);
        setPlayerReady(false);
        setIsLoadingAudio(false);
        
        if (onError) onError(error);
      }
      
      // Try to use best URL from globalAudioState
      if (globalAudioState && typeof globalAudioState.getBestAudioUrl === 'function') {
        const bestUrl = globalAudioState.getBestAudioUrl();
        if (bestUrl && isValidUrl(bestUrl) && bestUrl !== audioEl.src) {
          console.log("AUDIO PLAYER: Error recovery - switching to best URL:", bestUrl);
          audioEl.src = bestUrl;
          audioEl.load();
        }
      }
    });
    
    // Mark listeners as initialized to prevent duplication
    audioEl._listenersInitialized = true;
  }, [onAudioLoaded, onError, isValidUrl, updatePlayerReadiness]);

  // Generate global CSS styles
  const getGlobalStyles = useCallback((isMobile) => `
  @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
  @keyframes pulse { 
    0% { transform: scale(0.7); opacity: 0.3; } 
    50% { transform: scale(1.3); opacity: 0.7; }
    100% { transform: scale(0.7); opacity: 0.3; }
  }
  @keyframes fadeIn { 0% { opacity: 0; } 100% { opacity: 1; } }
  @keyframes breathe {
    0% { transform: scale(0.95); opacity: 0.7; }
    50% { transform: scale(1.05); opacity: 0.9; }
    100% { transform: scale(0.95); opacity: 0.7; }
  }
  
  /* Soothing transitions for all interactive elements */
  .music-player-mini button,
  .music-player-mini input[type="range"] {
    transition: all 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
  }
  
  /* Enhanced loading overlay for player */
  .player-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(28, 28, 46, 0.85);
    backdrop-filter: blur(4px);
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease;
    border-radius: 20px;
    animation: fadeIn 0.5s forwards;
  }
  
  .player-loading-overlay.visible {
    opacity: 1;
    pointer-events: auto;
  }
  
  .loading-pulse {
    width: 50px;
    height: 50px;
    background: radial-gradient(circle, #7d12ff 0%, #5636f3 70%);
    border-radius: 50%;
    animation: breathe 2s infinite alternate ease-in-out;
    box-shadow: 0 0 20px rgba(125, 18, 255, 0.5);
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: ${isMobile ? '20px' : '16px'};
    height: ${isMobile ? '20px' : '16px'};
    border-radius: 50%;
    background: #7d12ff;
    cursor: pointer;
    box-shadow: 0 0 6px rgba(125,18,255,0.4);
  }
  input[type="range"]::-moz-range-thumb {
    width: ${isMobile ? '20px' : '16px'};
    height: ${isMobile ? '20px' : '16px'};
    border-radius: 50%;
    background: #7d12ff;
    cursor: pointer;
    border: none;
    box-shadow: 0 0 6px rgba(125,18,255,0.4);
  }
  
  /* Hover animations */
  button:hover:not(:disabled) { transform: translateY(-2px); box-shadow: 0 6px 16px rgba(125,18,255,0.3); }
  button:active:not(:disabled) { transform: translateY(0); }
  
  /* Disabled button styling */
  button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  /* Hide toast notifications in ListenStep */
  body.in-listen-step .Toastify__toast-container {
    display: none !important;
  }
  
  @media (max-width: 768px) {
    input[type="range"] { height: 12px; padding: 8px 0; }
    input[type="checkbox"] { width: 20px; height: 20px; }
    select { height: 44px; }
  }
  `, []);

  // Toggle playlist visibility
  const togglePlaylist = useCallback(() => {
    setShowPlaylist(prev => !prev);
  }, []);

  // Toggle visualizer effects
  const toggleVisualizer = useCallback(() => {
    setUseVisualizer(prev => !prev);
  }, []);

  // Handle music selection
  const handleMusicSelect = useCallback((trackName) => {
    setSelectedMusic(trackName);
    setShowPlaylist(false);
    
    // Publish event to global audio state
    if (globalAudioState && typeof globalAudioState.publishEvent === 'function') {
      globalAudioState.publishEvent('music-selected', {
        trackName, 
        timestamp: Date.now()
      });
    }
    
    // Apply changes via callback if provided
    if (typeof onApplyChanges === 'function') {
      setIsLoadingAudio(true);
      setPlayerReady(false);
      
      onApplyChanges().catch(err => {
        console.error("AUDIO PLAYER: Error in onApplyChanges:", err);
        
        if (safeToastRef.current) {
          safeToastRef.current('error', 'Failed to change music track');
        }
        
        setIsLoadingAudio(false);
        updatePlayerReadiness();
      });
    }
  }, [setSelectedMusic, onApplyChanges, updatePlayerReadiness]);

  // Generate album art for display
  const getAlbumArt = useCallback((trackName = selectedMusic) => {
    const coverArtOptions = [
      'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1433086966358-54859d0ed716?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1501854140801-50d01698950b?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1528722828814-77b9b83aafb2?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1475274047050-1d0c0975c63e?w=600&h=600&fit=crop&auto=format'
    ];
    
    if (!trackName) return coverArtOptions[0];
    
    try {
      const cleanName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
      let hash = 0;
      
      for (let j = 0; j < cleanName.length; j++) {
        hash = (hash << 5) - hash + cleanName.charCodeAt(j);
        hash |= 0;
      }
      
      const index = Math.abs(hash) % coverArtOptions.length;
      return coverArtOptions[index];
    } catch (error) {
      console.error('Error getting cover art:', error);
      return coverArtOptions[0];
    }
  }, [selectedMusic]);

  // Update window dimensions on resize
  useEffect(() => {
    const handleDimensionUpdate = () => {
      setWindowWidth(window.innerWidth || 0);
      setWindowHeight(window.innerHeight || 0);
    };
    
    // Use a throttled update to prevent rapid firing
    let resizeTimeout;
    const throttledUpdate = () => {
      if (!resizeTimeout) {
        resizeTimeout = setTimeout(() => {
          handleDimensionUpdate();
          resizeTimeout = null;
        }, 100);
      }
    };
    
    window.addEventListener('resize', throttledUpdate);
    
    return () => {
      window.removeEventListener('resize', throttledUpdate);
      if (resizeTimeout) clearTimeout(resizeTimeout);
    };
  }, []);

  // Ensure proper container dimensions for wavesurfer
  const ensureContainerDimensions = useCallback((container, isMobile) => {
    if (!container) return;
    
    try {
      // Force important styles to prevent CSS conflicts
      container.style.setProperty('display', 'block', 'important');
      container.style.setProperty('visibility', 'visible', 'important');
      container.style.setProperty('width', '100%', 'important');
      container.style.setProperty('min-width', '300px', 'important');
      container.style.setProperty('height', isMobile ? '60px' : '80px', 'important');
      container.style.setProperty('min-height', isMobile ? '60px' : '80px', 'important');
      
      // Force reflow
      void container.offsetHeight;
      void container.offsetWidth;
      
      // Ensure container has an ID
      if (!container.id) {
        container.id = `wavesurfer-container-${Date.now()}`;
      }
    } catch (e) {
      console.warn("Error fixing container dimensions:", e);
    }
  }, []);

  // SIMPLIFIED: Initialize audio analyzer using GlobalAudioStateIntegration.js
  const initializeAudioAnalysis = useCallback(() => {
    if (analyzerInitializationInProgress.current || !mounted.current) {
      return false;
    }
    
    analyzerInitializationInProgress.current = true;
    console.log("AUDIO PLAYER: Initializing audio analysis for visualizer");
    
    try {
      // Initialize analyzer using the integration module
      initializeAnalyzerNode({
        audioElement: audioElementRef.current,
        analyzerRef,
        setAnalyzerNode,
        setAudioConnected: (connected) => {
          console.log("Audio analyzer connected:", connected);
        },
        onInitialized: (analyzer) => {
          console.log("Audio analyzer initialized successfully");
          analyzerInitializationInProgress.current = false;
        }
      });
      
      return true;
    } catch (error) {
      console.error("AUDIO PLAYER: Error in initializeAudioAnalysis:", error);
      analyzerInitializationInProgress.current = false;
      return false;
    }
  }, []);

  // SIMPLIFIED: Load audio into WaveSurfer
  const loadAudioIntoWaveSurfer = useCallback((url, wsInstance) => {
    if (!url || !wsInstance || wsInstance._destroyed || wsInstance._isBeingDestroyed) {
      console.warn("AUDIO PLAYER: Cannot load audio - invalid URL or WaveSurfer instance");
      return false;
    }
    
    console.log("AUDIO PLAYER: Loading audio into WaveSurfer:", url);
    
    try {
      // Create new abort controller for this operation
      const controller = new AbortController();
      wsInstance._abortController = controller;
      abortControllersRef.current.push(controller);
      
      // Load the audio
      wsInstance.load(url);
      return true;
    } catch (error) {
      console.error("AUDIO PLAYER: Error in loadAudioIntoWaveSurfer:", error);
      return false;
    }
  }, []);

  // SIMPLIFIED: Initialize WaveSurfer using safeCreateWaveSurfer
  function initializeWaveSurfer() {
    if (initializationInProgress.current || isDestroyingRef.current || !mounted.current) {
      console.log("AUDIO PLAYER: WaveSurfer initialization already in progress or component is unmounting");
      return null;
    }
    
    initializationInProgress.current = true;
    console.log("AUDIO PLAYER: Initializing WaveSurfer");
    
    try {
      // Get the appropriate container
      const container = isFullscreenMobile ? mobileWaveformContainerRef.current : waveformContainerRef.current;
      
      if (!container || !audioElementRef.current) {
        console.warn("AUDIO PLAYER: No container or audio element found for WaveSurfer initialization");
        initializationInProgress.current = false;
        return null;
      }
      
      // Ensure proper container dimensions
      ensureContainerDimensions(container, isMobile);
      
      // Use the safeCreateWaveSurfer helper
      const wsInstance = safeCreateWaveSurfer({
        container,
        WaveSurfer,
        audioElement: audioElementRef.current,
        isMobile,
        waveColor: 'rgba(255, 255, 255, 0.6)',
        progressColor: '#7d12ff',
        plugins: [],
        minPxPerSec: isMobile ? 50 : 100,
        scrollParent: true,
        fillParent: false,
        autoScroll: true,
        autoCenter: true,
        hideScrollbar: false,
        normalize: true,
        onReady: () => {
          console.log("AUDIO PLAYER: WaveSurfer ready");
          setWavesurferInitialized(true);
          setAudioLoaded(true);
          setIsLoadingAudio(false);
          
          // Update duration if available
          const dur = wsInstance.getDuration();
          if (dur && !isNaN(dur) && isFinite(dur)) {
            setDuration(dur);
            if (onAudioLoaded) {
              onAudioLoaded({ url: audioUrl, duration: dur, wavesurferReady: true });
            }
          }
          
          // Update player readiness
          updatePlayerReadiness();
          
          // Clear flag to allow future initialization
          initializationInProgress.current = false;
        },
        onError: (err) => {
          // Don't treat abort errors as fatal
          if (err?.name === 'AbortError' || err?.message?.includes('abort')) {
            console.log("AUDIO PLAYER: Load aborted (expected during cleanup)");
            return;
          }
          
          console.error("AUDIO PLAYER: WaveSurfer error:", err);
          setIsLoadingAudio(false);
          initializationInProgress.current = false;
          
          if (onError) onError(err);
        }
      });
      
      // Store in ref
      waveSurferRef.current = wsInstance;
      setWavesurferInitialized(true);
      
      // Set up safety timeout to ensure flag gets cleared eventually
      const timeoutId = setTimeout(() => {
        initializationInProgress.current = false;
      }, 5000);
      
      pendingTimeoutsRef.current.push(timeoutId);
      
      return wsInstance;
    } catch (error) {
      console.error("AUDIO PLAYER: Error initializing WaveSurfer:", error);
      initializationInProgress.current = false;
      return null;
    }
  }

  // Update WaveSurfer view when toggling fullscreen
  const updateWaveSurferView = useCallback(() => {
    if (!waveSurferRef.current || waveSurferRef.current._destroyed) return;
    
    try {
      waveSurferRef.current.drawer.containerWidth = waveSurferRef.current.drawer.container.clientWidth;
      waveSurferRef.current.drawBuffer();
      
      if (waveSurferRef.current.getCurrentTime && waveSurferRef.current.getDuration) {
        const currentTime = waveSurferRef.current.getCurrentTime();
        const duration = waveSurferRef.current.getDuration();
        if (duration > 0) {
          waveSurferRef.current.seekTo(currentTime / duration);
        }
      }
    } catch (err) {
      console.warn("Error updating WaveSurfer view:", err);
    }
  }, []);

  // ResizeObserver for WaveSurfer
  useEffect(() => {
    let observer;
    
    try {
      observer = new ResizeObserver(() => {
        if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
          updateWaveSurferView();
        }
      });
      
      if (waveformContainerRef.current) {
        observer.observe(waveformContainerRef.current);
      }
      if (mobileWaveformContainerRef.current) {
        observer.observe(mobileWaveformContainerRef.current);
      }
    } catch (err) {
      // Fallback to window resize if ResizeObserver isn't available
      const handleResize = () => {
        if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
          updateWaveSurferView();
        }
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [updateWaveSurferView]);

  // IMPROVED: Toggle playback using syncPlayback utility with better debouncing
  const togglePlayback = useCallback(() => {
    // Prevent rapid multiple clicks and check readiness
    if (!playerReady || !audioElementRef.current || actionsBlockedRef.current) {
      console.log("AUDIO PLAYER: Toggle blocked - player not ready or action in progress");
      return;
    }
    
    console.log("AUDIO PLAYER: Toggle playback called, current state:", isPlaying);
    
    // Set a blocking flag to prevent multiple rapid clicks
    actionsBlockedRef.current = true;
    
    try {
      // Use the syncPlayback utility to handle all edge cases
      syncPlayback({
        isPlaying: !isPlaying,
        audioElement: audioElementRef.current,
        waveSurfer: waveSurferRef.current,
        globalAudioState
      });
      
      // Let audio element events handle state update via the event listeners
    } catch (error) {
      console.error("AUDIO PLAYER: Error in togglePlayback:", error);
      
      // Direct fallback if the utility function fails
      try {
        if (audioElementRef.current) {
          if (isPlaying) {
            audioElementRef.current.pause();
          } else {
            audioElementRef.current.play().catch(err => {
              console.warn("AUDIO PLAYER: Play attempt failed:", err);
            });
          }
        }
      } catch (fallbackError) {
        console.error("AUDIO PLAYER: Fallback toggle also failed:", fallbackError);
      }
    }
    
    // Release blocking flag after a short delay
    setTimeout(() => {
      actionsBlockedRef.current = false;
    }, 300);
  }, [isPlaying, playerReady]);

  // SIMPLIFIED: Handle time skipping using syncTimePosition
  const handleSkip = useCallback((seconds) => {
    if (!playerReady || actionsBlockedRef.current) {
      return;
    }
    
    try {
      const newTime = Math.max(0, currentTime + seconds);
      
      // Use syncTimePosition utility
      syncTimePosition({
        currentTime: newTime,
        audioElement: audioElementRef.current,
        waveSurfer: waveSurferRef.current
      });
      
      setCurrentTime(newTime);
    } catch (error) {
      console.error("AUDIO PLAYER: Error during skip:", error);
    }
  }, [currentTime, playerReady]);

  // SIMPLIFIED: Handle seek using syncTimePosition
  const handleSeek = useCallback((e) => {
    if (!audioLoaded || !duration || !playerReady) return;
    
    try {
      const container = e.currentTarget;
      const rect = container.getBoundingClientRect();
      const relativeX = e.clientX - rect.left;
      const percentage = relativeX / rect.width;
      
      if (isNaN(percentage) || percentage < 0 || percentage > 1) return;
      
      const newTime = percentage * duration;
      
      // Use syncTimePosition utility
      syncTimePosition({
        currentTime: newTime,
        audioElement: audioElementRef.current,
        waveSurfer: waveSurferRef.current
      });
      
      setCurrentTime(newTime);
      
      // Notify global audio state if available
      if (globalAudioState && typeof globalAudioState.publishEvent === 'function') {
        globalAudioState.publishEvent('seek', { 
          percentage, 
          time: newTime,
          source: 'waveform-click' 
        });
      }
    } catch (error) {
      console.error("AUDIO PLAYER: Error in seek handler:", error);
    }
  }, [audioLoaded, duration, playerReady]);

  // Handle volume changes
  const handleVolumeChange = useCallback((newVolume) => {
    setVolume(newVolume);
    
    try {
      // Update WaveSurfer volume
      if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
        waveSurferRef.current.setVolume(newVolume);
      }
      
      // Update audio element volume
      if (audioElementRef.current) {
        audioElementRef.current.volume = newVolume;
      }
    } catch (error) {
      console.error("AUDIO PLAYER: Error changing volume:", error);
    }
  }, []);

  // Toggle mute state
  const toggleMute = useCallback(() => {
    if (!playerReady) return;
    
    const newMuteState = !isMuted;
    setIsMuted(newMuteState);
    
    try {
      // Update WaveSurfer mute state
      if (waveSurferRef.current && !waveSurferRef.current._destroyed) {
        if (typeof waveSurferRef.current.setMute === 'function') {
          waveSurferRef.current.setMute(newMuteState);
        } else {
          // Fallback if setMute is not available
          waveSurferRef.current.setVolume(newMuteState ? 0 : volume);
        }
      }
      
      // Update audio element mute state
      if (audioElementRef.current) {
        audioElementRef.current.muted = newMuteState;
      }
    } catch (error) {
      console.error("AUDIO PLAYER: Error toggling mute:", error);
    }
  }, [isMuted, volume, playerReady]);

  // SIMPLIFIED: Toggle fullscreen using prepareFullscreenTransition
  const toggleFullscreen = useCallback(
    debounce(() => {
      if (actionsBlockedRef.current) return;
      
      actionsBlockedRef.current = true;
      console.log("Toggling fullscreen mode, current state:", isFullscreen);
      
      // Use the transition preparation function
      prepareFullscreenTransition({
        direction: isFullscreen ? 'exit' : 'enter',
        audioElement: audioElementRef.current,
        waveSurfer: waveSurferRef.current,
        currentUrl: audioUrl,
        isPlaying,
        currentTime: currentTime || 0,
        duration: duration || 0
      });
    
      // Toggle the fullscreen flag
      setIsFullscreen(prev => !prev);
      
      // Release blocking after a delay
      setTimeout(() => {
        actionsBlockedRef.current = false;
      }, 500);
    }, 300),
    [isFullscreen, audioUrl, isPlaying, currentTime, duration]
  );

  // SIMPLIFIED: Audio mixing with GlobalAudioState
  const handleAudioMixingFromListenStep = useCallback(async (volumeOverride, filtersOverride) => {
    const toastId = safeToastRef.current?.loading('Applying your changes...') || null;
    setIsMixingAudio(true);
    setPlayerReady(false);
    
    try {
      // Let GlobalAudioState handle the mixing process
      globalAudioState.startAudioMixing();
      
      // Prepare the payload with proper filter transformation
      const mixPayload = {
        tts_audio: ttsKey ? `TTS/${ttsKey.replace(/^TTS\//, '')}` : '',
        bg_audio: selectedMusic ? `Background_Music/${selectedMusic}.mp3` : '',
        bg_volume: volumeOverride?.bg_volume || 0.2,
        tts_volume: volumeOverride?.tts_volume || 1.0,
        overall_volume: volumeOverride?.overall_volume || 1.0,
        filters: transformFiltersForBackend(filtersOverride)
      };
      
      console.log("AUDIO PLAYER: Sending mixing request with payload:", mixPayload);
      
      // Make the request
      const response = await fetch(`${baseUrl}/get-mixed-audio`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(mixPayload)
      });
      
      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }
      
      const mixResult = await response.json();
      
      // Use GlobalAudioState to extract and validate the URL
      const extractedUrl = globalAudioState.extractMixedAudioUrl(mixResult);
      
      if (extractedUrl.success && extractedUrl.url) {
        // Let GlobalAudioState handle URL completion with proper locking
        globalAudioState.completeAudioMixing(extractedUrl.url);
        
        // Update our component state
        setAudioUrl(extractedUrl.url);
        
        if (safeToastRef.current) {
          safeToastRef.current('success', 'Your changes have been applied!', { id: toastId, autoClose: 3000 });
        }
        
        updatePlayerReadiness();
        return extractedUrl.url;
      } else {
        // Try direct file_path access as fallback
        if (mixResult.data?.file_path && isValidUrl(mixResult.data.file_path)) {
          const directUrl = mixResult.data.file_path;
          globalAudioState.completeAudioMixing(directUrl);
          setAudioUrl(directUrl);
          
          if (safeToastRef.current) {
            safeToastRef.current('success', 'Your changes have been applied!', { id: toastId, autoClose: 3000 });
          }
          
          updatePlayerReadiness();
          return directUrl;
        }
        
        throw new Error("Failed to extract valid URL from mix result");
      }
    } catch (error) {
      console.error("AUDIO PLAYER: Audio mixing error:", error);
      
      // Let GlobalAudioState handle the failure case
      globalAudioState.failAudioMixing(error.message);
      
      // Try to use fallback URL
      const fallbackUrl = globalAudioState.getBestAudioUrl();
      
      if (fallbackUrl) {
        setAudioUrl(fallbackUrl);
        
        if (safeToastRef.current) {
          safeToastRef.current('warning', 'Could not apply changes. Using fallback audio.', { id: toastId, autoClose: 5000 });
        }
        
        updatePlayerReadiness();
        return fallbackUrl;
      } else {
        if (safeToastRef.current) {
          safeToastRef.current('error', 'Failed to apply changes.', { id: toastId, autoClose: 5000 });
        }
        
        throw error;
      }
    } finally {
      setIsMixingAudio(false);
    }
  }, [baseUrl, ttsKey, selectedMusic, setIsMixingAudio, isValidUrl, transformFiltersForBackend, updatePlayerReadiness]);
  
  // SIMPLIFIED: Export audio using GlobalAudioState
  const handleExportAudioHandler = useCallback(async () => {
    if (!playerReady) {
      if (safeToastRef.current) {
        safeToastRef.current('info', 'Please wait until audio is fully loaded before downloading');
      }
      return;
    }
    
    // Use provided export handler if available
    if (typeof onExportAudio === 'function') {
      try {
        await onExportAudio();
        return;
      } catch (err) {
        console.warn("AUDIO PLAYER: Error with provided export handler:", err);
      }
    }
    
    // Determine the best URL to download
    let downloadUrl = '';
    
    if (globalAudioState && typeof globalAudioState.getBestAudioUrl === 'function') {
      downloadUrl = globalAudioState.getBestAudioUrl();
    }
    
    if (!downloadUrl) {
      downloadUrl = audioUrl;
    }
    
    // Validate URL
    if (!downloadUrl || !isValidUrl(downloadUrl)) {
      if (safeToastRef.current) {
        safeToastRef.current('error', 'No audio available to download');
      }
      return;
    }
    
    console.log("AUDIO PLAYER: Downloading from URL:", downloadUrl);
    
    if (safeToastRef.current) {
      safeToastRef.current('info', 'Preparing download...');
    }
    
    try {
      // Fetch the audio file
      const response = await fetch(downloadUrl, {
        method: 'GET',
        headers: { 'Accept': 'audio/mpeg, audio/*', 'Cache-Control': 'no-cache' },
        cache: 'no-store'
      });
      
      if (!response.ok) {
        throw new Error(`Failed to fetch audio: ${response.status} ${response.statusText}`);
      }
      
      // Create a blob from the response
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      
      // Create a download link and click it
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = 'meditation-' + Date.now() + '.mp3';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      
      // Clean up
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }, 200);
      
      if (safeToastRef.current) {
        safeToastRef.current('success', 'Downloading your meditation audio');
      }
    } catch (err) {
      console.warn("AUDIO PLAYER: Error using fetch method, trying direct link:", err);
      
      try {
        // Fallback to direct link download
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'meditation-' + Date.now() + '.mp3';
        a.target = '_blank';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        
        setTimeout(() => {
          document.body.removeChild(a);
        }, 200);
        
        if (safeToastRef.current) {
          safeToastRef.current('success', 'Downloading your meditation audio');
        }
      } catch (directErr) {
        console.error("AUDIO PLAYER: All download methods failed:", directErr);
        
        if (safeToastRef.current) {
          safeToastRef.current('error', 'Unable to download audio. Please try again.');
        }
      }
    }
  }, [audioUrl, onExportAudio, isValidUrl, playerReady]);

  // SIMPLIFIED: Reset session using GlobalAudioState
  const handleResetSessionHandler = useCallback(() => {
    // Use provided reset handler if available
    if (typeof onReset === 'function') {
      try {
        onReset();
        return;
      } catch (error) {
        console.error("AUDIO PLAYER: Error in provided reset handler:", error);
      }
    }
    
    try {
      // Clean up WaveSurfer
      if (waveSurferRef.current) {
        try {
          safeDestroyWaveSurfer(waveSurferRef.current);
          waveSurferRef.current = null;
        } catch (e) {
          console.warn("AUDIO PLAYER: Error destroying WaveSurfer:", e);
        }
      }
      
      // Clean up audio element
      if (audioElementRef.current) {
        try {
          audioElementRef.current.pause();
          audioElementRef.current.src = '';
          audioElementRef.current.load();
        } catch (e) {
          console.warn("AUDIO PLAYER: Error cleaning up audio element:", e);
        }

        audioElementRef.current = null;
      }
      
      // Clean up audio context
      if (audioContextRef.current) {
        try {
          audioContextRef.current.close();
        } catch (e) {
          console.warn("AUDIO PLAYER: Error closing AudioContext:", e);
        }
        audioContextRef.current = null;
      }
      
      // Clean up analyzer
      analyzerRef.current = null;
      setAnalyzerNode(null);
      
      // Reset global state if available
      if (globalAudioState && typeof globalAudioState.resetAllState === 'function') {
        globalAudioState.resetAllState();
      }
      
      // Reset component state
      setAudioUrl('');
      setIsPlaying(false);
      setAudioLoaded(false);
      setIsLoadingAudio(true);
      setCurrentTime(0);
      setDuration(0);
      setPlayerReady(false);
      
      if (safeToastRef.current) {
        safeToastRef.current('success', 'Session reset. Ready for a new meditation.');
      }
    } catch (error) {
      console.error("AUDIO PLAYER: Error in reset handler:", error);
      
      if (safeToastRef.current) {
        safeToastRef.current('error', 'Failed to reset session');
      }
    }
  }, [onReset, safeDestroyWaveSurfer]);

  // Render visualizer based on current state
  const renderVisualizer = useCallback(() => {
    if (!useVisualizer) {
      // Simple pulsing circle when visualizer is disabled
      return (
        <div style={{ 
          width: '100%', 
          height: '100%', 
          backgroundColor: '#02073c', 
          borderRadius: '50%', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center' 
        }}>
          <div style={{ 
            width: '60%', 
            height: '60%', 
            borderRadius: '50%', 
            background: 'radial-gradient(circle, #7d12ff 0%, #5636f3 70%, #02073c 100%)', 
            opacity: isPlaying ? 0.8 : 0.5, 
            transition: 'all 0.3s ease', 
            animation: isPlaying ? 'breathe 3s infinite alternate' : 'none' 
          }}></div>
        </div>
      );
    }
    
    // Use the VisualizerLoader component which will handle analyzer integration
    try {
      return (
        <VisualizerLoader
          analyzerNode={analyzerNode || analyzerRef.current}
          isPlaying={isPlaying}
          size={visualizerSize}
          audioElement={audioElementRef.current}
          fallbackOnly={!analyzerNode && !analyzerRef.current}
        />
      );
    } catch (error) {
      console.error("Error rendering visualizer:", error);
      
      // Fallback to simple pulsing circle if visualization fails
      return (
        <div style={{ 
          width: '100%', 
          height: '100%', 
          backgroundColor: '#02073c', 
          borderRadius: '50%', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center' 
        }}>
          <div style={{ 
            width: '60%', 
            height: '60%', 
            borderRadius: '50%', 
            background: 'radial-gradient(circle, #7d12ff 0%, #5636f3 70%, #02073c 100%)', 
            opacity: 0.6, 
            transition: 'all 0.3s ease',
            animation: 'breathe 3s infinite alternate'
          }}></div>
        </div>
      );
    }
  }, [analyzerNode, isPlaying, useVisualizer, visualizerSize]);

  // Callback to update waveform data for sharing between standard and fullscreen modes
  const updateWaveformData = useCallback((data) => {
    if (Array.isArray(data) && data.length > 0) {
      waveformDataRef.current = data;
    }
  }, []);

  // SIMPLIFIED: Render full screen visualizer
  const renderFullScreenVisualizer = useCallback(() => {
    // Make sure we have a valid audio element before passing to the visualizer
    const audioEl = audioElementRef.current;
    
    // Ensure the audio context and analyzer are available
    const activeAnalyzer = analyzerRef.current || analyzerNode;
    
    return (
      <FullScreenVisualizer
        audioElement={audioEl}
        isPlaying={isPlaying}
        onPlayPause={togglePlayback}
        onToggleFullScreen={toggleFullscreen}
        colorPrimary="#7d12ff"
        colorSecondary="#5636f3"
        backgroundColor="#02073c"
        externalAnalyzer={activeAnalyzer}
        externalAudioContext={audioContextRef.current}
        currentTime={currentTime}
        duration={duration}
        onSkip={handleSkip}
        onSeek={(time) => {
          syncTimePosition({
            currentTime: time,
            audioElement: audioEl,
            waveSurfer: waveSurferRef.current
          });
        }}
        musicLibrary={musicLibrary}
        selectedMusic={selectedMusic}
        onMusicSelect={handleMusicSelect}
        waveformData={waveformDataRef.current}
        onVolumeChange={handleVolumeChange}
        onMuteToggle={toggleMute}
        volume={volume}
        isMuted={isMuted}
        useExistingAudioElement={true}
        waveformColor='rgba(255, 255, 255, 0.6)'
        progressColor='#7d12ff'
        suppressToasts={true}
      />
    );
  }, [
    isPlaying, 
    togglePlayback, 
    toggleFullscreen, 
    currentTime, 
    duration, 
    handleSkip, 
    syncTimePosition, 
    musicLibrary, 
    selectedMusic, 
    handleMusicSelect,
    handleVolumeChange,
    toggleMute,
    volume,
    isMuted,
    analyzerNode
  ]);

  // SIMPLIFIED: WaveSurfer container ref callback
  function waveformContainerRefCallback(node) {
    if (!node) return;
  
    // Store reference based on current mode
    if (isFullscreen) {
      mobileWaveformContainerRef.current = node;
    } else {
      waveformContainerRef.current = node;
    }
  
    // Apply critical styles
    node.style.cssText = `
      display: block !important;
      visibility: visible !important;
      width: 100% !important;
      min-width: 300px !important;
      height: ${isMobile ? '60px' : '80px'} !important;
      min-height: ${isMobile ? '60px' : '80px'} !important;
      opacity: 1 !important;
      pointer-events: auto !important;
      position: relative !important;
      overflow: hidden !important;
      background-color: rgba(0,0,0,0.2) !important;
    `;
    
    // Force reflow
    void node.offsetHeight;
    void node.offsetWidth;
    
    // Ensure the node has an ID
    if (!node.id) {
      node.id = `wavesurfer-container-${Date.now()}`;
    }
    
    // If we already have a WaveSurfer instance in this container, reuse it
    if (node._wavesurfer && !node._wavesurfer._destroyed) {
      console.log("AUDIO PLAYER: Found existing WaveSurfer instance in container");
      waveSurferRef.current = node._wavesurfer;
      setWavesurferInitialized(true);
      return;
    }
  
    // Check container dimensions
    const rect = node.getBoundingClientRect();
    if (rect.width > 20 && rect.height > 20) {
      // Container looks good, initialize WaveSurfer
      setTimeout(() => {
        if (mounted.current && (!waveSurferRef.current || waveSurferRef.current._destroyed)) {
          const wsInstance = initializeWaveSurfer();
          
          if (wsInstance && audioUrl && isValidUrl(audioUrl)) {
            loadAudioIntoWaveSurfer(audioUrl, wsInstance);
          }
        }
      }, 50);
    } else {
      console.warn(`AUDIO PLAYER: Container has invalid dimensions: ${rect.width}x${rect.height}, delaying initialization`);
    }
  }

  // Effect for monitoring player readiness
  useEffect(() => {
    updatePlayerReadiness();
  }, [audioLoaded, isLoadingAudio, updatePlayerReadiness]);

  // Main useEffect for initialization
  useEffect(() => {
    console.log("AUDIO PLAYER: Component mounted, initializing...");
    mounted.current = true;
    isMountedRef.current = true;
    transitionCompleteRef.current = false;
    isDestroyingRef.current = false;
    initializationInProgress.current = false;
    
    // Store the initial URL
    if (initialAudioUrl && isValidUrl(initialAudioUrl)) {
      audioUrlRef.current = initialAudioUrl;
      setAudioUrl(initialAudioUrl);
    }

    // Check for transition state
    const isInTransition = checkTransitionState();
    
    // Initialize audio with proper approach
    const initializeAudioComponent = () => {
      if (isInTransition) {
        console.log("AUDIO PLAYER: In transition state");
        
        // Find best URL from transition state
        let bestUrl = null;
        
        if (window.__stableTransitionUrl && isValidUrl(window.__stableTransitionUrl)) {
          bestUrl = window.__stableTransitionUrl;
        } else if (globalAudioState.state.stableUrlReference) {
          bestUrl = globalAudioState.state.stableUrlReference;
        } else if (window.__currentAudioUrl) {
          bestUrl = window.__currentAudioUrl;
        } else if (window.__mixedAudioUrl) {
          bestUrl = window.__mixedAudioUrl;
        } else {
          // Get from GlobalAudioState
          bestUrl = globalAudioState.getBestAudioUrl();
        }
        
        if (bestUrl && isValidUrl(bestUrl)) {
          console.log("AUDIO PLAYER: Using transition URL:", bestUrl);
          setAudioUrl(bestUrl);
          audioUrlRef.current = bestUrl;
        }
        
        // Use audio element from transition if available
        if (window.__transitionAudioElement) {
          audioElementRef.current = window.__transitionAudioElement;
          console.log("AUDIO PLAYER: Using transition audio element");
          
          // Make sure it has the correct URL
          if (bestUrl && isValidUrl(bestUrl)) {
            audioElementRef.current.src = bestUrl;
            audioElementRef.current.load();
          }
          
          // Setup listeners if needed
          setupAudioElementListeners(audioElementRef.current);
          
          // Initialize analyzer for visualizer
          initializeAudioAnalysis();
          
          // Mark as loaded
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        } else {
          // Create a new audio element
          console.log("AUDIO PLAYER: Creating new audio element");
          const newAudio = new Audio();
          newAudio.crossOrigin = "anonymous";
          newAudio.preload = "auto";
          
          // Setup listeners
          setupAudioElementListeners(newAudio);
          
          // Set source
          if (bestUrl && isValidUrl(bestUrl)) {
            newAudio.src = bestUrl;
            newAudio.load();
          }
          
          // Store reference
          audioElementRef.current = newAudio;
          
          // Initialize analyzer
          initializeAudioAnalysis();
          
          // Store in global state
          if (globalAudioState) {
            globalAudioState._audioElement = newAudio;
          }
        }
        
        // Initialize WaveSurfer after audio element is ready
        setTimeout(() => {
          if (mounted.current) {
            initializeWaveSurfer();
          }
        }, 500);
      } else {
        // Normal initialization path
        console.log("AUDIO PLAYER: Normal initialization path");
        
        // Use the imported initialization function
        const result = initAudioFromUtils({
          audioUrl: initialAudioUrl || audioUrlRef.current,
          globalAudioState,
          audioElementRef,
          waveSurferRef,
          setAudioElement: (element) => {
            audioElementRef.current = element;
            if (globalAudioState && !globalAudioState._audioElement) {
              globalAudioState._audioElement = element;
            }
          },
          createFallbackElement: createFallbackAudioElement,
          onAudioReady: handleAudioReady
        });
        
        if (result && result.success) {
          console.log("AUDIO PLAYER: Audio initialization successful");
          
          // Update URL state
          if (result.audioElement && result.audioElement.src) {
            setAudioUrl(result.audioElement.src);
            audioUrlRef.current = result.audioElement.src;
          } else if (initialAudioUrl) {
            setAudioUrl(initialAudioUrl);
            audioUrlRef.current = initialAudioUrl;
          }
          
          // Initialize analyzer
          setTimeout(() => {
            if (mounted.current) {
              initializeAudioAnalysis();
            }
          }, 500);
          
          // Initialize WaveSurfer
          setTimeout(() => {
            if (mounted.current) {
              initializeWaveSurfer();
            }
          }, 800);
        } else {
          console.warn("AUDIO PLAYER: Audio initialization was not successful");
          
          // Try to use best URL from GlobalAudioState
          const bestUrl = globalAudioState.getBestAudioUrl();
          if (bestUrl && isValidUrl(bestUrl)) {
            createFallbackAudioElement(bestUrl);
          } else if (initialAudioUrl && isValidUrl(initialAudioUrl)) {
            createFallbackAudioElement(initialAudioUrl);
          } else {
            // Try silent audio
            if (globalAudioState && typeof globalAudioState.getSilentAudioUrl === 'function') {
              const silentUrl = globalAudioState.getSilentAudioUrl();
              createFallbackAudioElement(silentUrl);
            }
          }
        }
      }
      
      setComponentInitialized(true);
    };
    
    // Start initialization
    initializeAudioComponent();
    
    // Safety timeout to ensure audio eventually loads
    const safetyTimeout = setTimeout(() => {
      if (mounted.current && !audioLoaded) {
        console.warn("AUDIO PLAYER: Audio load timeout, using fallback");
        
        let fallbackUrl = null;
        
        // Try to get URL from various sources
        if (globalAudioState && typeof globalAudioState.getBestAudioUrl === 'function') {
          fallbackUrl = globalAudioState.getBestAudioUrl();
        } else if (initialAudioUrl) {
          fallbackUrl = initialAudioUrl;
        } else {
          // Try silent audio
          if (globalAudioState && typeof globalAudioState.getSilentAudioUrl === 'function') {
            fallbackUrl = globalAudioState.getSilentAudioUrl();
          }
        }
        
        if (fallbackUrl && isValidUrl(fallbackUrl)) {
          createFallbackAudioElement(fallbackUrl);
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        }
      }
    }, 8000);
    
    pendingTimeoutsRef.current.push(safetyTimeout);
    
    // Clean up on unmount
    return () => {
      console.log("AUDIO PLAYER: Component unmounting, cleaning up resources");
      
      mounted.current = false;
      isMountedRef.current = false;
      isDestroyingRef.current = true;
      
      // Check for short-lived component
      const unmountTime = Date.now();
      
      // Clear all timeouts
      pendingTimeoutsRef.current.forEach(id => {
        if (id) clearTimeout(id);
      });
      pendingTimeoutsRef.current = [];
      
      // Abort all pending operations
      abortControllersRef.current.forEach(controller => {
        try {
          if (controller && typeof controller.abort === 'function') {
            controller.abort();
          }
        } catch (e) {
          // Ignore abort errors
        }
      });
      abortControllersRef.current = [];
      
      // Clean up WaveSurfer with helper
      if (waveSurferRef.current) {
        try {
          safeDestroyWaveSurfer(waveSurferRef.current);
        } catch (err) {
          console.warn("AUDIO PLAYER: Error destroying WaveSurfer:", err);
        }
        waveSurferRef.current = null;
      }
      
      // Don't destroy shared audio elements
      if (audioElementRef.current && audioElementRef.current !== globalAudioState._audioElement) {
        try {
          audioElementRef.current.pause();
          audioElementRef.current.src = '';
          audioElementRef.current.load();
          audioElementRef.current = null;
        } catch (err) {
          console.warn("AUDIO PLAYER: Error cleaning up audio element:", err);
        }
      }
      
      // Clean up audio context
      if (audioContextRef.current) {
        try {
          audioContextRef.current.close();
        } catch (e) {
          console.warn("AUDIO PLAYER: Error closing AudioContext:", e);
        }
        audioContextRef.current = null;
      }
      
      // Clean up analyzer
      if (analyzerRef.current && typeof analyzerRef.current._cleanup === 'function') {
        try {
          analyzerRef.current._cleanup();
        } catch (e) {
          // Ignore cleanup errors
        }
      }
      
      analyzerRef.current = null;
      setAnalyzerNode(null);
      
      // Publish cleanup event
      if (globalAudioState && typeof globalAudioState.publishEvent === 'function') {
        try {
          globalAudioState.publishEvent('audio-component-cleanup', {
            id: instanceId,
            timestamp: Date.now()
          });
        } catch (e) {
          // Ignore errors in event publishing
        }
      }
    };
  }, [
    initialAudioUrl,
    isValidUrl,
    createFallbackAudioElement,
    initializeWaveSurfer,
    loadAudioIntoWaveSurfer,
    initializeAudioAnalysis,
    safeDestroyWaveSurfer,
    audioLoaded,
    checkTransitionState,
    handleAudioReady,
    initAudioFromUtils,
    audioUrl,
    setupAudioElementListeners
  ]);

  // Listen for audio URL updates from GlobalAudioState
  useEffect(() => {
    const handleUrlUpdate = (event) => {
      if (event.detail && event.detail.url && mounted.current) {
        const newUrl = event.detail.url;
        if (isValidUrl(newUrl) && newUrl !== audioUrl) {
          console.log("AUDIO PLAYER: Received URL update from GlobalAudioState:", newUrl);
          setAudioUrl(newUrl);
          audioUrlRef.current = newUrl;
        }
      }
    };
    
    window.addEventListener('audio-url-updated', handleUrlUpdate);
    
    return () => {
      window.removeEventListener('audio-url-updated', handleUrlUpdate);
    };
  }, [audioUrl, isValidUrl]);

  // Update audioUrlRef when audioUrl changes
  useEffect(() => {
    if (audioUrl && isValidUrl(audioUrl)) {
      audioUrlRef.current = audioUrl;
    }
  }, [audioUrl, isValidUrl]);

  return (
    <div
      className="listen-step-container"
      style={{
        margin: 0,
        padding: 0,
        width: '100%',
        height: isFullscreen ? '100vh' : 'auto',
        position: isFullscreen ? 'fixed' : 'relative',
        top: isFullscreen ? '0' : 'auto',
        left: isFullscreen ? '0' : 'auto',
        zIndex: isFullscreen ? 1000 : 'auto',
        backgroundColor: isFullscreen ? '#161616' : 'transparent',
        transition: 'height 0.3s ease',
        overflow: isFullscreen ? 'hidden' : 'visible',
      }}
    >
      {/* Global styles with toast notification control */}
      <style dangerouslySetInnerHTML={{ __html: getGlobalStyles(isMobile) }} />
  
      {isFullscreen ? (
        <div
          className="fullscreen-visualizer-wrapper"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            minHeight: '300px',
            zIndex: 1000,
            backgroundColor: '#161616',
          }}
        >
          {audioElementRef.current ? (
            renderFullScreenVisualizer()
          ) : (
            // Fallback if audioElement isn't ready yet
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              color: 'white'
            }}>
              <div>Preparing visualizer...</div>
            </div>
          )}
        </div>
      ) : (
        <div className="standard-player-container">
          {(!isFullscreen || !isMobile) && (
            <div className="step-header" style={{ textAlign: 'center', padding: '10px', color: '#fff' }}>
              <h2>Your Meditation is Ready</h2>
              <p>Listen, save, or create a new meditation</p>
            </div>
          )}
          
          {/* Main player UI */}
          <div className="music-player-mini" style={{
            position: 'relative',
            width: '100%',
            maxWidth: '800px',
            margin: '24px auto',
            padding: '20px',
            backgroundColor: '#1C1C2E',
            backgroundImage: 'linear-gradient(135deg, #1E1C2E 0%, #0C0C14 100%)',
            borderRadius: '20px',
            boxShadow: '0 10px 30px rgba(0,0,0,0.3)',
            transition: 'all 0.3s ease'
          }}>
            {/* Loading overlay */}
            {!playerReady && isLoadingAudio && (
              <div className="player-loading-overlay visible">
                <div className="loading-pulse"></div>
              </div>
            )}
            
            {/* Track info & Visualizer */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '20px' }}>
              <div style={{ position: 'relative', width: '80px', height: '80px', borderRadius: '50%', flexShrink: 0, overflow: 'hidden', backgroundColor: '#02073c' }}>
                {renderVisualizer()}
                <button onClick={toggleVisualizer} style={{
                  position: 'absolute',
                  bottom: '4px',
                  right: '4px',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  color: 'white',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '10px',
                  zIndex: 10
                }}>
                  <IoImage size={12} />
                </button>
              </div>
              <div style={{ flex: 1, minWidth: 0, color: '#fff' }}>
              <h3 style={{ fontSize: '18px', fontWeight: '600', marginBottom: '8px' }}>{trackInfo.title}</h3>
                <p style={{ fontSize: '14px', color: 'rgba(255,255,255,0.7)', margin: 0 }}>{trackInfo.artist}</p>
              </div>
              {isMobile && (
                <button onClick={toggleFullscreen} style={{
                  background: 'linear-gradient(135deg, #7d12ff 0%, #5636f3 100%)',
                  border: 'none',
                  borderRadius: '50%',
                  width: '44px',
                  height: '44px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  boxShadow: '0 4px 10px rgba(125,18,255,0.3)',
                  color: '#fff'
                }}>
                  {isFullscreen ? <IoContract size={24} /> : <IoExpand size={24} />}
                </button>
              )}
            </div>
            
            {/* Waveform container */}
            <div style={{
              marginBottom: '10px',
              position: 'relative',
              backgroundColor: 'rgba(20,20,30,0.3)',
              borderRadius: '8px',
              padding: '8px',
              overflow: 'hidden',
              minHeight: '110px'
            }}>
              {/* Progress bar for visual continuity (always visible) */}
              <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: duration > 0 ? `${(currentTime / duration) * 100}%` : '0%',
                backgroundColor: 'rgba(125, 18, 255, 0.15)',
                borderRadius: '4px 0 0 4px',
                zIndex: 1,
                pointerEvents: 'none'
              }} />
              
              {/* Waveform container */}
              <div 
                ref={waveformContainerRefCallback} 
                style={{
                  height: isMobile ? '60px' : '80px',
                  width: '100%',
                  position: 'relative',
                  marginBottom: '6px',
                  overflow: 'hidden',
                  borderRadius: '4px',
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  cursor: playerReady ? 'pointer' : 'default',
                  opacity: playerReady ? 1 : 0.7
                }} 
                onClick={playerReady ? handleSeek : undefined} 
              />
              
              {/* Timeline container */}
              <div 
                ref={timelineContainerRef} 
                style={{ 
                  height: '20px', 
                  width: '100%', 
                  overflow: 'hidden',
                  opacity: 0.7,
                  backgroundColor: 'rgba(0,0,0,0.2)',
                  borderRadius: '4px'
                }} 
              />
              
              {/* Time display */}
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                color: 'white',
                fontSize: '12px',
                marginTop: '6px',
                padding: '0 2px'
              }}>
                <span>{formatTime(currentTime)}</span>
                <span>{formatTime(duration)}</span>
              </div>
              
              {/* Loading overlay for just the waveform */}
              {isLoadingAudio && (
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '4px',
                  backgroundColor: 'rgba(0,0,0,0.4)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 20
                }}>
                  <div style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    border: '3px solid rgba(125,18,255,0.3)',
                    borderTopColor: '#7d12ff',
                    animation: 'spin 1s linear infinite'
                  }} />
                </div>
              )}
            </div>
            
            {/* Player controls */}
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: '20px' }}>
              <button 
                onClick={() => handleSkip(-10)} 
                disabled={!playerReady || isLoadingAudio}
                style={{
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  border: 'none',
                  borderRadius: '50%',
                  width: '44px',
                  height: '44px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: playerReady ? 'pointer' : 'default',
                  color: '#fff',
                  opacity: playerReady ? 1 : 0.5,
                  transition: 'all 0.3s ease'
                }}
              >
                <IoPlaySkipBack size={24} />
              </button>
              
              <button 
                onClick={togglePlayback} 
                disabled={!playerReady || isLoadingAudio}
                style={{
                  background: playerReady 
                    ? 'linear-gradient(135deg, #7d12ff 0%, #5636f3 100%)'
                    : 'rgba(125,18,255,0.3)',
                  border: 'none',
                  borderRadius: '50%',
                  width: '60px',
                  height: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: playerReady ? 'pointer' : 'default',
                  boxShadow: playerReady ? '0 6px 15px rgba(125,18,255,0.3)' : 'none',
                  color: '#fff',
                  opacity: playerReady && !isLoadingAudio ? 1 : 0.6,
                  position: 'relative',
                  transition: 'all 0.3s ease',
                  pointerEvents: playerReady && !isLoadingAudio ? 'auto' : 'none'
                }}
              >
                {isLoadingAudio ? (
                  <div style={{
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    border: '3px solid rgba(255,255,255,0.3)',
                    borderTopColor: '#fff',
                    animation: 'spin 1s linear infinite'
                  }} />
                ) : (
                  isPlaying ? <IoPauseCircle size={32} /> : <IoPlayCircle size={32} />
                )}
              </button>
              
              <button 
                onClick={() => handleSkip(10)} 
                disabled={!playerReady || isLoadingAudio}
                style={{
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  border: 'none',
                  borderRadius: '50%',
                  width: '44px',
                  height: '44px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: playerReady ? 'pointer' : 'default',
                  color: '#fff',
                  opacity: playerReady ? 1 : 0.5,
                  transition: 'all 0.3s ease'
                }}
              >
                <IoPlaySkipForward size={24} />
              </button>
              
              <button 
                onClick={toggleMute} 
                disabled={!playerReady || isLoadingAudio}
                style={{
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  border: 'none',
                  borderRadius: '50%',
                  width: '44px',
                  height: '44px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: playerReady ? 'pointer' : 'default',
                  color: '#fff',
                  opacity: playerReady ? 1 : 0.5,
                  transition: 'all 0.3s ease'
                }}
              >
                {isMuted ? <IoVolumeOff size={24} /> : <IoVolumeHigh size={24} />}
              </button>
            </div>
            
            {/* Volume slider */}
            {!isMobile && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px', marginBottom: '20px', padding: '0 16px' }}>
                <IoVolumeOff size={16} style={{ color: 'rgba(255,255,255,0.6)' }} />
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={volume}
                  onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
                  disabled={!playerReady}
                  style={{
                    flex: 1,
                    height: '4px',
                    appearance: 'none',
                    backgroundColor: 'rgba(255,255,255,0.1)',
                    outline: 'none',
                    borderRadius: '2px',
                    background: `linear-gradient(to right, #7d12ff 0%, #7d12ff ${volume * 100}%, rgba(255,255,255,0.1) ${volume * 100}%, rgba(255,255,255,0.1) 100%)`,
                    opacity: playerReady ? 1 : 0.5,
                    cursor: playerReady ? 'pointer' : 'default',
                  }}
                />
                <IoVolumeHigh size={16} style={{ color: 'rgba(255,255,255,0.6)' }} />
              </div>
            )}
            
            {/* Bottom row buttons */}
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '12px' }}>
              <button onClick={togglePlaylist} style={{
                flex: 1,
                padding: '12px',
                backgroundColor: 'rgba(255,255,255,0.05)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                color: 'rgba(255,255,255,0.8)',
                fontSize: '14px',
                fontWeight: '500',
                cursor: 'pointer'
              }}>
                <IoList size={18} />
                <span>Playlist</span>
              </button>
              <button onClick={handleExportAudioHandler} style={{
                flex: 1,
                padding: '12px',
                backgroundColor: 'rgba(255,255,255,0.05)',
                border: '1px solid rgba(255,255,255,0.1)',
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                color: 'rgba(255,255,255,0.8)',
                fontSize: '14px',
                fontWeight: '500',
                cursor: 'pointer'
              }}>
                <IoDownload size={18} />
                <span>Download</span>
              </button>
              {!isMobile && (
                <button onClick={toggleFullscreen} style={{
                  flex: 1,
                  padding: '12px',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  border: '1px solid rgba(255,255,255,0.1)',
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  color: 'rgba(255,255,255,0.8)',
                  fontSize: '14px',
                  fontWeight: '500',
                  cursor: 'pointer'
                }}>
                  <IoExpand size={18} />
                  <span>Fullscreen</span>
                </button>
              )}
              {typeof onReset === 'function' && (
                <button onClick={handleResetSessionHandler} style={{
                  flex: 1,
                  padding: '12px',
                  backgroundColor: 'rgba(255,255,255,0.05)',
                  border: '1px solid rgba(255,255,255,0.1)',
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  color: 'rgba(255,255,255,0.8)',
                  fontSize: '14px',
                  fontWeight: '500',
                  cursor: 'pointer'
                }}>
                  <IoRefresh size={18} />
                  <span>New</span>
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Playlist modal - rendered conditionally */}
      {showPlaylist && !isFullscreen && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0,0,0,0.8)',
          backdropFilter: 'blur(5px)',
          WebkitBackdropFilter: 'blur(5px)',
          zIndex: 1010,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px'
        }}>
          <div style={{
            width: '100%',
            maxWidth: '600px',
            maxHeight: '90vh',
            backgroundColor: '#1C1C2E',
            borderRadius: '20px',
            overflow: 'hidden',
            boxShadow: '0 10px 30px rgba(0,0,0,0.5)'
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '20px',
              borderBottom: '1px solid rgba(255,255,255,0.1)'
            }}>
              <h3 style={{ margin: 0, color: 'white' }}>Music Library</h3>
              <button onClick={togglePlaylist} style={{
                background: 'transparent',
                border: 'none',
                color: 'white',
                cursor: 'pointer',
                display: 'flex'
              }}>
                <IoClose size={24} />
              </button>
            </div>
            <div style={{
              maxHeight: 'calc(90vh - 80px)',
              overflowY: 'auto',
              padding: '10px 0'
            }}>
              {Array.isArray(musicLibrary) && musicLibrary.length > 0 ? (
                musicLibrary.map((track, index) => {
                  if (!track || !track.name) return null;
                  
                  const displayName = track.displayName ||
                    track.name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
                  
                  const isActive = track.name === selectedMusic;
                  
                  return (
                    <div
                      key={track.name || index}
                      onClick={() => handleMusicSelect(track.name)}
                      style={{
                        padding: '15px 20px',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        borderLeft: isActive ? '3px solid #7d12ff' : '3px solid transparent',
                        backgroundColor: isActive ? 'rgba(125,18,255,0.1)' : 'transparent',
                        transition: 'all 0.2s ease'
                      }}
                    >
                      <div style={{ width: '30px', textAlign: 'center', color: isActive ? '#7d12ff' : 'rgba(255,255,255,0.5)' }}>
                        {(index + 1).toString().padStart(2, '0')}
                        </div>
                      <div style={{ marginLeft: '15px', flex: 1 }}>
                        <div style={{ 
                          color: isActive ? '#7d12ff' : 'white', 
                          fontWeight: isActive ? '600' : 'normal'
                        }}>
                          {displayName}
                        </div>
                        <div style={{ fontSize: '14px', color: 'rgba(255,255,255,0.5)', marginTop: '4px' }}>
                          {track.duration ? formatTime(track.duration) : 'Pause Music'}
                        </div>
                      </div>
                      {isActive && (
                        <div style={{ 
                          width: '16px', 
                          height: '16px', 
                          borderRadius: '50%', 
                          backgroundColor: '#7d12ff',
                          marginLeft: '10px'
                        }}></div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div style={{ padding: '30px', textAlign: 'center', color: 'rgba(255,255,255,0.5)' }}>
                  No music tracks available
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListenStep;