// VisualizationIntegration.js
// A seamless integration layer for connecting audio players with visualizers
// without disrupting audio playback

/**
 * This file provides a simple API to connect audio visualization components
 * to audio playback without risking playback interruption or "getting stuck"
 * issues that commonly happen with the Web Audio API.
 */

import React, { useState, useEffect, useRef } from 'react';
import { createRobustAnalyzer, cleanupAnalyzer } from './EnhancedAudioAnalyzer';

// Store connection info for debugging and troubleshooting
const connectionRegistry = {
  visualizersConnected: 0,
  lastConnectedAt: null,
  activeVisualizers: [],
  errors: []
};

/**
 * Connect a visualizer component to an audio element safely
 * 
 * @param {Object} options Configuration options
 * @param {HTMLAudioElement} options.audioElement The audio element to visualize
 * @param {Function} options.onAnalyzerReady Callback when analyzer is ready
 * @param {Object} options.analyzerRef Optional React ref to store the analyzer
 * @param {Function} options.setAnalyzerNode Optional React state setter for analyzer
 * @param {boolean} options.voiceFocus Whether to emphasize voice frequencies
 * @param {number} options.sensitivity Visualization sensitivity multiplier
 * @returns {Object} Connection info and cleanup function
 */
export function connectVisualizer(options) {
  const {
    audioElement,
    onAnalyzerReady,
    analyzerRef,
    setAnalyzerNode,
    voiceFocus = true,
    sensitivity = 1.8
  } = options;
  
  if (!audioElement) {
    console.warn("Cannot connect visualizer: No audio element provided");
    return { 
      success: false, 
      cleanup: () => {},
      error: "No audio element provided" 
    };
  }
  
  try {
    // Create analyzer that won't interfere with audio playback
    const analyzer = createRobustAnalyzer(audioElement, {
      sensitivity,
      voiceFocus
    });
    
    // Update references if provided
    if (analyzerRef && 'current' in analyzerRef) {
      analyzerRef.current = analyzer;
    }
    
    if (typeof setAnalyzerNode === 'function') {
      setAnalyzerNode(analyzer);
    }
    
    // Share with global state if available (for other visualizers)
    if (window.globalAudioState) {
      window.globalAudioState._analyzer = analyzer;
    }
    
    // Track connection info
    connectionRegistry.visualizersConnected++;
    connectionRegistry.lastConnectedAt = Date.now();
    
    // Create a unique ID for this connection
    const connectionId = `visualizer-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    
    // Store active visualizer info
    connectionRegistry.activeVisualizers.push({
      id: connectionId,
      connectedAt: Date.now(),
      analyzer
    });
    
    // Call ready callback if provided
    if (typeof onAnalyzerReady === 'function') {
      onAnalyzerReady(analyzer);
    }
    
    // Return success with cleanup function
    return {
      success: true,
      analyzer,
      connectionId,
      cleanup: () => {
        // Remove from active visualizers
        connectionRegistry.activeVisualizers = 
          connectionRegistry.activeVisualizers.filter(v => v.id !== connectionId);
        
        // Clean up analyzer resources if it's the last visualizer using it
        if (connectionRegistry.activeVisualizers.length === 0) {
          cleanupAnalyzer(analyzer);
        }
      }
    };
  } catch (error) {
    // Log error and return failure
    console.error("Error connecting visualizer:", error);
    
    connectionRegistry.errors.push({
      timestamp: Date.now(),
      message: error.message,
      stack: error.stack
    });
    
    return {
      success: false,
      cleanup: () => {},
      error: error.message
    };
  }
}

/**
 * A React hook for using audio visualization in functional components
 * with automatic cleanup on unmount
 * 
 * @param {HTMLAudioElement} audioElement The audio element to visualize
 * @param {Object} options Configuration options
 */
export function useAudioVisualization(audioElement, options = {}) {
  const { sensitivity = 1.8, voiceFocus = true } = options;
  
  // Create state
  const [analyzer, setAnalyzer] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  
  // Connect on mount or when audio element changes
  useEffect(() => {
    if (!audioElement) return;
    
    const connection = connectVisualizer({
      audioElement,
      sensitivity,
      voiceFocus,
      onAnalyzerReady: (newAnalyzer) => {
        setAnalyzer(newAnalyzer);
        setIsConnected(true);
      }
    });
    
    if (!connection.success) {
      setError(connection.error);
    }
    
    // Clean up on unmount
    return () => {
      connection.cleanup();
      setIsConnected(false);
    };
  }, [audioElement, sensitivity, voiceFocus]);
  
  return {
    analyzer,
    isConnected,
    error
  };
}

/**
 * Get the current audio visualization levels (bass, mid, treble)
 * Useful for direct use in visualizers
 * 
 * @param {AnalyserNode} analyzer The analyzer node
 * @param {boolean} isPlaying Whether audio is currently playing
 * @returns {Object} Audio levels normalized to 0-1
 */
export function getAudioLevels(analyzer, isPlaying = false) {
    if (!analyzer) {
      return simulateAudioLevels(isPlaying);
    }
    
    try {
      // If the analyzer has a getAudioLevels method, use it
      if (typeof analyzer.getAudioLevels === 'function') {
        return analyzer.getAudioLevels();
      }
      
      // Otherwise, calculate levels manually with safety checks
      if (!analyzer.frequencyDataArray) {
        try {
          analyzer.frequencyDataArray = new Uint8Array(analyzer.frequencyBinCount || 1024);
        } catch (err) {
          console.warn("Error creating frequency data array:", err);
          return simulateAudioLevels(isPlaying);
        }
      }
      
      try {
        analyzer.getByteFrequencyData(analyzer.frequencyDataArray);
      } catch (err) {
        console.warn("Error getting frequency data:", err);
        return simulateAudioLevels(isPlaying);
      }
      
      // Calculate frequency bands safely
      let bass = 0, mid = 0, treble = 0;
      let bassCount = 0, midCount = 0, trebleCount = 0;
      const binCount = analyzer.frequencyBinCount || analyzer.frequencyDataArray.length || 1024;
      
      // Low frequencies (bass)
      const bassRange = Math.floor(binCount * 0.15);
      for (let i = 1; i < bassRange; i++) {
        bass += analyzer.frequencyDataArray[i] || 0;
        bassCount++;
      }
      
      // Mid frequencies
      const midRange = Math.floor(binCount * 0.5);
      for (let i = bassRange; i < midRange; i++) {
        mid += analyzer.frequencyDataArray[i] || 0;
        midCount++;
      }
      
      // High frequencies (treble)
      const trebleRange = Math.floor(binCount * 0.8);
      for (let i = midRange; i < trebleRange; i++) {
        treble += analyzer.frequencyDataArray[i] || 0;
        trebleCount++;
      }
      
      // Use a safe sensitivity value with fallback
      const sensitivity = typeof analyzer.sensitivity === 'number' ? 
        analyzer.sensitivity : 1.8;
      
      // Normalize and apply sensitivity safely
      bass = bassCount > 0 ? Math.pow((bass / bassCount) / 255, 0.8) * sensitivity : 0;
      mid = midCount > 0 ? Math.pow((mid / midCount) / 255, 0.7) * sensitivity : 0;
      treble = trebleCount > 0 ? Math.pow((treble / trebleCount) / 255, 0.6) * sensitivity : 0;
      
      // Apply voice focus if needed
      const voiceFocus = typeof analyzer.voiceFocus === 'boolean' ? 
        analyzer.voiceFocus : true;
        
      if (voiceFocus) {
        mid *= 1.5;  // Boost mids for voice
        bass *= 1.2;  // Slightly boost bass too
      }
      
      return { bass, mid, treble, isSimulated: false };
    } catch (err) {
      console.warn("Error getting audio levels:", err);
      return simulateAudioLevels(isPlaying);
    }
  }
  
  // Helper for simulating audio levels when real data isn't available
  function simulateAudioLevels(isPlaying) {
    const t = Date.now() / 1000;
    const sensitivity = 1.8;
    
    // Generate simulated values that look realistic
    const bass = isPlaying ? 
      (0.3 + Math.sin(t * 0.6) * 0.15) * sensitivity : 
      (0.1 + Math.sin(t * 0.3) * 0.05) * sensitivity;
    
    const mid = isPlaying ? 
      (0.4 + Math.sin(t * 0.8 + 0.5) * 0.2) * sensitivity : 
      (0.15 + Math.sin(t * 0.4 + 0.3) * 0.05) * sensitivity;
    
    const treble = isPlaying ? 
      (0.25 + Math.sin(t * 0.7 + 0.9) * 0.15) * sensitivity : 
      (0.1 + Math.sin(t * 0.35 + 0.7) * 0.05) * sensitivity;

  
  return { bass, mid, treble, isSimulated: true };
}

/**
 * Get diagnostic information about the audio visualization system
 * Useful for debugging
 */
export function getVisualizationDiagnostics() {
  return {
    visualizersConnected: connectionRegistry.visualizersConnected,
    lastConnectedAt: connectionRegistry.lastConnectedAt,
    activeVisualizers: connectionRegistry.activeVisualizers.length,
    errors: connectionRegistry.errors.slice(-5), // Last 5 errors
    
    // Global state info
    globalState: {
      hasGlobalAudioState: !!window.globalAudioState,
      hasGlobalAnalyzer: !!(window.globalAudioState && window.globalAudioState._analyzer),
      hasConnectionRegistry: !!window._audioConnectionRegistry,
      fallbacksCreated: window._audioConnectionRegistry?.fallbacksCreated || 0
    }
  };
}

export default {
  connectVisualizer,
  useAudioVisualization,
  getAudioLevels,
  getVisualizationDiagnostics
};