// Enhanced ThirdEyeVisualizer.js - With VisualizationIntegration
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connectVisualizer, getAudioLevels } from '../audio/VisualizationIntegration';

// Helper functions from original ThirdEyeVisualizer
const interpolateColors = (color1, color2, factor) => {
  factor = Math.max(0, Math.min(1, factor));
  const r1 = parseInt(color1.slice(1, 3), 16);
  const g1 = parseInt(color1.slice(3, 5), 16);
  const b1 = parseInt(color1.slice(5, 7), 16);
  const r2 = parseInt(color2.slice(1, 3), 16);
  const g2 = parseInt(color2.slice(3, 5), 16);
  const b2 = parseInt(color2.slice(5, 7), 16);
  const r = Math.round(r1 + factor * (r2 - r1));
  const g = Math.round(g1 + factor * (g2 - g1));
  const b = Math.round(b1 + factor * (b2 - b1));
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`;
};

const polygon = (ctx, x, y, radius, npoints, rotation = 0) => {
  const angle = (Math.PI * 2) / npoints;
  ctx.beginPath();
  for (let a = rotation; a < Math.PI * 2 + rotation; a += angle) {
    const sx = x + Math.cos(a) * radius;
    const sy = y + Math.sin(a) * radius;
    if (a === rotation) ctx.moveTo(sx, sy);
    else ctx.lineTo(sx, sy);
  }
  ctx.closePath();
};

const mapValue = (value, inMin, inMax, outMin, outMax) => {
  return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

// ThirdEyeVisualizer Component
const ThirdEyeVisualizer = ({
  analyzerNode = null,
  isPlaying = false,
  size = 300,
  primaryColor = '#7d12ff',
  secondaryColor = '#5636f3',
  tertiaryColor = '#9e65ff',
  sensitivity = 1.5,
  voiceFocus = true,
  audioElement = null, // NEW: Added audio element prop 
  id = 'default-visualizer'
}) => {
  // Refs and state
  const canvasRef = useRef(null);
  const animationRef = useRef(null);
  const dataArrayRef = useRef(null);
  const timeRef = useRef(0);
  const lastTimeRef = useRef(0);
  const mountedRef = useRef(true);
  const [dimensions, setDimensions] = useState({ width: 100, height: 100, dpr: 1 });
  const [error, setError] = useState(null);
  const [analyzerReady, setAnalyzerReady] = useState(false);
  const [visualStyle, setVisualStyle] = useState(0); // 0, 1, or 2
  const [localAnalyzer, setLocalAnalyzer] = useState(analyzerNode);
  const connectionRef = useRef(null);

  // Use safe colors from props; fallback to defaults if needed
  const safeColors = {
    fallback: {
      primary: primaryColor,
      secondary: secondaryColor,
      tertiary: tertiaryColor,
      background: '#02073c'
    }
  };
  const safeSensitivity = typeof sensitivity === 'number' && !isNaN(sensitivity) ? sensitivity : 1.5;

  // Setup analyzer from passed in analyzer or by creating our own via VisualizationIntegration
  useEffect(() => {
    mountedRef.current = true;
    
    // If no analyzer is passed but we have an audio element, create one
    if (!analyzerNode && audioElement && !connectionRef.current) {
      console.log("ThirdEyeVisualizer: Creating analyzer via VisualizationIntegration");
      
      // Connect visualizer to audio element
      const visualizerConnection = connectVisualizer({
        audioElement,
        onAnalyzerReady: (newAnalyzer) => {
          console.log("ThirdEyeVisualizer: Successfully connected to audio");
          setLocalAnalyzer(newAnalyzer);
          setAnalyzerReady(true);
        },
        sensitivity: safeSensitivity,
        voiceFocus
      });
      
      // Store connection for cleanup
      connectionRef.current = visualizerConnection;
    } else if (analyzerNode) {
      // Use the passed in analyzer
      setLocalAnalyzer(analyzerNode);
      setAnalyzerReady(true);
    }
    
    return () => {
      mountedRef.current = false;
      // Clean up visualizer connection if we created one
      if (connectionRef.current && connectionRef.current.cleanup) {
        connectionRef.current.cleanup();
        connectionRef.current = null;
      }
    };
  }, [analyzerNode, audioElement, safeSensitivity, voiceFocus]);

  // Canvas Dimension Handling
  useEffect(() => {
    const updateDimensions = () => {
      if (!canvasRef.current) return;
      const dpr = window.devicePixelRatio || 1;
      const rect = canvasRef.current.getBoundingClientRect();
      const safeWidth = Math.max(100, rect.width || 100);
      const safeHeight = Math.max(100, rect.height || 100);
      setDimensions({ width: safeWidth, height: safeHeight, dpr });
      canvasRef.current.width = safeWidth * dpr;
      canvasRef.current.height = safeHeight * dpr;
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) ctx.scale(dpr, dpr);
    };
    updateDimensions();
    let resizeTimeout;
    const handleResize = () => {
      if (resizeTimeout) clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(updateDimensions, 100);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (resizeTimeout) clearTimeout(resizeTimeout);
    };
  }, [size]);

  // Get audio data using the integrated getAudioLevels helper
  const getAudioData = useCallback(() => {
    if (!localAnalyzer) return { bass: 0, mid: 0, treble: 0, isReal: false };
    
    // Use the helper from VisualizationIntegration to get audio levels
    return getAudioLevels(localAnalyzer, isPlaying);
  }, [localAnalyzer, isPlaying]);

  // Visualization Style Functions
  const drawStyle0 = useCallback((ctx, canvasWidth, canvasHeight, audio, time) => {
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
    const radius = Math.min(centerX, centerY) * 0.9;
    ctx.fillStyle = safeColors.fallback.background;
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    const intensity = isPlaying ? 0.8 + (audio.mid / 255) * 0.2 : 0.6 + Math.sin(time * 0.5) * 0.1;
    const waveCount = isPlaying ? 8 + Math.floor((audio.bass / 255) * 4) : 8;
    const detail = isPlaying ? 80 + Math.floor((audio.treble / 255) * 40) : 100;
    ctx.strokeStyle = safeColors.fallback.primary;
    ctx.lineWidth = 1;
    for (let wave = 0; wave < waveCount; wave++) {
      const phaseOffset = (Math.PI * 2 * wave) / waveCount;
      ctx.beginPath();
      for (let i = 0; i <= detail; i++) {
        const angle = (i / detail) * Math.PI * 8 + time + phaseOffset;
        const radiusOffset = (Math.sin(angle * 3) * 0.15 + 0.85) * intensity;
        const waveRadius = radius * (0.4 + (i / detail) * 0.4) * radiusOffset;
        const x = centerX + Math.cos(angle) * waveRadius;
        const y = centerY + Math.sin(angle) * waveRadius;
        if (i === 0) ctx.moveTo(x, y);
        else ctx.lineTo(x, y);
      }
      ctx.stroke();
    }
  }, [isPlaying, safeColors]);

  const drawStyle1 = useCallback((ctx, canvasWidth, canvasHeight, audio, time) => {
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
    const radius = Math.min(centerX, centerY) * 0.9;
    ctx.fillStyle = safeColors.fallback.background;
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    const intensity = isPlaying ? (audio.bass / 255) * 0.4 + 0.6 : 0.7 + Math.sin(time * 0.3) * 0.1;
    const density = isPlaying ? Math.floor(30 + (audio.treble / 255) * 20) : 40;
    const ringCount = 80;
    const dotsPerRing = density;
    for (let ring = 0; ring < ringCount; ring++) {
      const progress = ring / ringCount;
      const ringRadius = radius * progress;
      let color;
      if (progress < 0.3) {
        const localProgress = progress / 0.3;
        color = interpolateColors(safeColors.fallback.primary, '#ffff00', localProgress);
      } else {
        const localProgress = (progress - 0.3) / 0.7;
        color = interpolateColors('#ffff00', safeColors.fallback.secondary, localProgress);
      }
      ctx.fillStyle = color;
      for (let dot = 0; dot < dotsPerRing; dot++) {
        const angle = (dot / dotsPerRing) * Math.PI * 2 + time * 0.1 * (ring % 2 === 0 ? 1 : -1);
        const x = centerX + Math.cos(angle) * ringRadius;
        const y = centerY + Math.sin(angle) * ringRadius;
        const baseDotSize = ringRadius < 30 ? 2 : 1.5;
        const dotSizeModifier = isPlaying
          ? 1 + Math.sin(time * 3 + ring * 0.2 + dot * 0.1) * 0.3 * intensity
          : 1 + Math.sin(time * 0.5 + ring * 0.1) * 0.1;
        const dotSize = baseDotSize * dotSizeModifier;
        ctx.beginPath();
        ctx.arc(x, y, dotSize, 0, Math.PI * 2);
        ctx.fill();
      }
    }
  }, [isPlaying, safeColors]);

  const drawStyle2 = useCallback((ctx, canvasWidth, canvasHeight, audio, time) => {
    const centerX = canvasWidth / 2;
    const centerY = canvasHeight / 2;
    const radius = Math.min(centerX, centerY) * 0.9;
    
    // Map audio values to visual parameters
    const bass = audio.bass || 0;
    const mid = audio.mid || 0;
    const treble = audio.treble || 0;
    
    const mapBass = Math.max(30, mapValue(bass * 255, 0, 255, 30, 200));
    const mapMid = Math.max(20, mapValue(mid * 255, 0, 255, -80, 200));
    const mapTreble = Math.max(40, mapValue(treble * 255, 0, 255, 180, 350));
    
    // Background
    ctx.fillStyle = safeColors.fallback.background;
    ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    
    // Draw geometric patterns
    ctx.save();
    ctx.translate(centerX, centerY);
    const pieces = isPlaying ? 20 : 15;
    const rotationSpeed = time * (isPlaying ? 0.25 : 0.15);
    
    for (let i = 0; i < pieces; i += 0.5) {
      ctx.rotate((Math.PI * 2) / (pieces / 2));
      ctx.save();
      ctx.strokeStyle = safeColors.fallback.primary;
      ctx.rotate(time * 0.002);
      ctx.lineWidth = isPlaying ? 0.7 : 0.4;
      polygon(ctx, mapBass + i, mapBass - i, 3, 3, rotationSpeed);
      ctx.stroke();
      ctx.restore();
      
      if (i % 1 === 0) {
        ctx.save();
        ctx.strokeStyle = safeColors.fallback.secondary;
        ctx.lineWidth = isPlaying ? 0.3 : 0.2;
        polygon(ctx, mapMid + i / 2, mapMid - i * 2, 7, 7, -rotationSpeed * 0.5);
        ctx.stroke();
        ctx.restore();
      }
      
      if (i % 1.5 < 0.1) {
        ctx.save();
        ctx.strokeStyle = safeColors.fallback.tertiary;
        ctx.lineWidth = isPlaying ? 0.8 : 0.5;
        const scaleAmt = 0.5 + Math.sin(time * 0.2) * 0.2;
        ctx.scale(scaleAmt, scaleAmt);
        ctx.rotate(time * 0.002);
        polygon(ctx, mapTreble + i / 2, mapTreble - i / 2, 3, 3, rotationSpeed * 0.3);
        ctx.stroke();
        ctx.restore();
      }
    }
    ctx.restore();
    
    // Add glow effect when playing
    if (isPlaying) {
      const glowRadius = 20 + Math.sin(time * 2) * 8;
      const gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, centerX + glowRadius);
      gradient.addColorStop(0, 'rgba(125,18,255,0.01)');
      gradient.addColorStop(0.7, 'rgba(125,18,255,0.05)');
      gradient.addColorStop(1, 'rgba(125,18,255,0)');
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    }
  }, [isPlaying, safeColors]);

  // Animation Loop
  useEffect(() => {
    if (!canvasRef.current || !mountedRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) { setError(new Error("Could not get canvas context")); return; }
    const dpr = dimensions.dpr || window.devicePixelRatio || 1;
    const canvasWidth = dimensions.width || canvas.width / dpr;
    const canvasHeight = dimensions.height || canvas.height / dpr;
    
    const animate = (timestamp) => {
      if (!mountedRef.current || !canvasRef.current) return;
      const deltaTime = timestamp - (lastTimeRef.current || timestamp);
      lastTimeRef.current = timestamp;
      const cappedDelta = Math.min(deltaTime, 100);
      timeRef.current += cappedDelta * 0.001;
      const audioData = getAudioData();
      try {
        switch (visualStyle) {
          case 0:
            drawStyle0(ctx, canvasWidth, canvasHeight, audioData, timeRef.current);
            break;
          case 1:
            drawStyle1(ctx, canvasWidth, canvasHeight, audioData, timeRef.current);
            break;
          case 2:
            drawStyle2(ctx, canvasWidth, canvasHeight, audioData, timeRef.current);
            break;
          default:
            drawStyle0(ctx, canvasWidth, canvasHeight, audioData, timeRef.current);
        }
      } catch (err) {
        console.warn("ThirdEyeVisualizer: Error in drawing function:", err);
        ctx.fillStyle = '#000000';
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);
      }
      animationRef.current = requestAnimationFrame(animate);
    };
    animationRef.current = requestAnimationFrame(animate);
    return () => {
      if (animationRef.current) cancelAnimationFrame(animationRef.current);
    };
  }, [dimensions, isPlaying, drawStyle0, drawStyle1, drawStyle2, getAudioData, visualStyle]);

  // Handle Click to Change Visual Style
  const handleVisualizerClick = useCallback(() => {
    setVisualStyle(prev => (prev + 1) % 3);
  }, []);

  if (error) return null;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        overflow: 'hidden',
        position: 'absolute',
        top: 0,
        left: 0,
        boxShadow: 'inset 0 0 30px rgba(0,0,0,0.5)',
        cursor: 'pointer'
      }}
      data-visualizer-id={id}
      onClick={handleVisualizerClick}
    >
      <canvas
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%',
          display: 'block',
          objectFit: 'cover'
        }}
      />
    </div>
  );
};

export default React.memo(ThirdEyeVisualizer);