// EnhancedAudioAnalyzer.js - Comprehensive audio analyzer solution
// A robust audio analyzer implementation that prevents playback issues

// Global registry to track audio connections across the application
if (typeof window !== 'undefined' && !window._audioConnectionRegistry) {
    window._audioConnectionRegistry = {
      connectedElements: new WeakMap(),
      activeAnalyzers: [],
      activeContexts: [],
      lastError: null,
      fallbacksCreated: 0,
      initialized: false
    };
  }
  
  /**
   * Gets or creates a singleton AudioContext for reuse
   */
  function getSharedAudioContext() {
    // Check if we already have an active context
    if (window._audioConnectionRegistry.activeContexts.length > 0) {
      const mostRecentContext = window._audioConnectionRegistry.activeContexts[
        window._audioConnectionRegistry.activeContexts.length - 1
      ];
      
      // Only reuse if it's not closed
      if (mostRecentContext && mostRecentContext.state !== 'closed') {
        return mostRecentContext;
      }
    }
    
    // Create a new context
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!AudioContext) return null;
    
    try {
      const context = new AudioContext();
      window._audioConnectionRegistry.activeContexts.push(context);
      
      // Store in global state if available
      if (window.globalAudioState) {
        window.globalAudioState._audioContext = context;
      }
      
      return context;
    } catch (err) {
      console.error("Error creating AudioContext:", err);
      return null;
    }
  }
  
  /**
   * Creates a robust audio analyzer with proper fallback handling
   */
  export function createRobustAnalyzer(audioElement, options = {}) {
    // Ensure options has default values to prevent undefined errors
    const { sensitivity = 1.8, voiceFocus = true } = options;
    
    console.log("Creating robust audio analyzer");
    
    // Make sure _audioConnectionRegistry exists before using it
    if (typeof window !== 'undefined' && !window._audioConnectionRegistry) {
      window._audioConnectionRegistry = {
        connectedElements: new WeakMap(),
        activeAnalyzers: [],
        activeContexts: [],
        lastError: null,
        fallbacksCreated: 0,
        initialized: false
      };
    }
    
    // Step 1: Check if we have an existing analyzer for this element
    if (window._audioConnectionRegistry && window._audioConnectionRegistry.connectedElements &&
        window._audioConnectionRegistry.connectedElements.has(audioElement)) {
      try {
        const existingConnection = window._audioConnectionRegistry.connectedElements.get(audioElement);
        
        // Try to reuse existing analyzer
        if (existingConnection && existingConnection.analyzer) {
          console.log("Using existing analyzer for this audio element");
          
          // Make sure it has the required properties
          existingConnection.analyzer.sensitivity = sensitivity;
          existingConnection.analyzer.voiceFocus = voiceFocus;
          
          return existingConnection.analyzer;
        }
      } catch (err) {
        console.warn("Error retrieving existing connection:", err);
      }
    }
    
    // Step 2: Try to get or create an AudioContext
    const audioCtx = getSharedAudioContext();
    if (!audioCtx) {
      console.warn("No AudioContext available, using fallback analyzer");
      return createFallbackAnalyzer(sensitivity, voiceFocus, audioElement);
    }
    
    // Resume AudioContext if suspended (needed for autoplay policy)
    if (audioCtx.state === 'suspended') {
      console.log("Resuming suspended AudioContext");
      audioCtx.resume().catch(err => console.warn("Error resuming AudioContext:", err));
    }
    
    try {
      // Step 3: Create an analyzer node
      const analyzer = audioCtx.createAnalyser();
      analyzer.fftSize = 2048;
      analyzer.smoothingTimeConstant = 0.75; // More responsive
      analyzer.minDecibels = -90;
      analyzer.maxDecibels = -10;
      
      // CRITICAL: Add explicit properties to prevent 'undefined' errors
      analyzer.sensitivity = sensitivity;
      analyzer.voiceFocus = voiceFocus;
      
      // Set up easy access to data arrays
      analyzer.frequencyDataArray = new Uint8Array(analyzer.frequencyBinCount);
      analyzer.timeDataArray = new Uint8Array(analyzer.frequencyBinCount);
      
      // Add higher-level method to get audio levels for visualization with better error handling
      analyzer.getAudioLevels = function() {
        try {
          this.getByteFrequencyData(this.frequencyDataArray);
          
          // Calculate frequency bands with enhanced sensitivity
          let bass = 0, mid = 0, treble = 0;
          let bassCount = 0, midCount = 0, trebleCount = 0;
          
          // Low frequencies (bass) - frequencies roughly up to 250Hz
          const bassRange = Math.floor(this.frequencyBinCount * 0.15);
          for (let i = 1; i < bassRange; i++) {
            bass += this.frequencyDataArray[i] || 0;
            bassCount++;
          }
          
          // Mid frequencies - up to about 2kHz
          const midRange = Math.floor(this.frequencyBinCount * 0.5);
          for (let i = bassRange; i < midRange; i++) {
            mid += this.frequencyDataArray[i] || 0;
            midCount++;
          }
          
          // High frequencies (treble) - 2kHz and up
          const trebleRange = Math.floor(this.frequencyBinCount * 0.8);
          for (let i = midRange; i < trebleRange; i++) {
            treble += this.frequencyDataArray[i] || 0;
            trebleCount++;
          }
          
          // Get sensitivity from the analyzer object (it's properly set now)
          const appliedSensitivity = this.sensitivity;
          
          // Normalize and apply enhanced sensitivity with safety checks
          bass = bassCount > 0 ? Math.pow((bass / bassCount) / 255, 0.8) * appliedSensitivity : 0;
          mid = midCount > 0 ? Math.pow((mid / midCount) / 255, 0.7) * appliedSensitivity : 0;
          treble = trebleCount > 0 ? Math.pow((treble / trebleCount) / 255, 0.6) * appliedSensitivity : 0;
          
          // Apply voice-focused EQ curve if requested
          if (this.voiceFocus) {
            mid *= 1.5;  // Boost mids for voice
            bass *= 1.2;  // Slightly boost bass too
          }
          
          return { bass, mid, treble, isSimulated: false };
        } catch (err) {
          console.warn("Error getting audio levels:", err);
          
          // Return simulated data as fallback
          const t = Date.now() / 1000;
          const isPlaying = audioElement ? !audioElement.paused : false;
          
          const bass = isPlaying ? 
            (0.3 + Math.sin(t * 0.6) * 0.15) * this.sensitivity : 
            (0.1 + Math.sin(t * 0.3) * 0.05) * this.sensitivity;
          
          const mid = isPlaying ? 
            (0.4 + Math.sin(t * 0.8 + 0.5) * 0.2) * this.sensitivity : 
            (0.15 + Math.sin(t * 0.4 + 0.3) * 0.05) * this.sensitivity;
          
          const treble = isPlaying ? 
            (0.25 + Math.sin(t * 0.7 + 0.9) * 0.15) * this.sensitivity : 
            (0.1 + Math.sin(t * 0.35 + 0.7) * 0.05) * this.sensitivity;
          
          return { bass, mid, treble, isSimulated: true };
        }
      };
      
      // Step 4: Try THREE different connection strategies in order of preference
      
      // STRATEGY 1: Try normal direct connection if not already connected
      let connectionSuccessful = false;
      let connectionSource = null;
      
      // Make sure the registry is initialized before using
      if (!window._audioConnectionRegistry.connectedElements) {
        window._audioConnectionRegistry.connectedElements = new WeakMap();
      }
      
      if (!window._audioConnectionRegistry.connectedElements.has(audioElement)) {
        try {
          // Create a new MediaElementSource and connect it
          const source = audioCtx.createMediaElementSource(audioElement);
          source.connect(audioCtx.destination); // Connect to output so audio plays
          source.connect(analyzer);             // Connect to analyzer
          
          // Store the connection for future reference
          connectionSource = source;
          window._audioConnectionRegistry.connectedElements.set(audioElement, {
            source: source,
            analyzer: analyzer,
            context: audioCtx,
            timestamp: Date.now()
          });
          
          connectionSuccessful = true;
          console.log("Successfully created new MediaElementSource connection");
        } catch (err) {
          console.warn("Direct connection failed:", err);
          // Continue to next strategy
        }
      }
      
      // STRATEGY 2: Try to use the existing connection if available
      if (!connectionSuccessful && window._audioConnectionRegistry.connectedElements.has(audioElement)) {
        try {
          const existingConnection = window._audioConnectionRegistry.connectedElements.get(audioElement);
          if (existingConnection && existingConnection.source) {
            // Connect the existing source to our new analyzer
            existingConnection.source.connect(analyzer);
            
            // Update the registry with our new analyzer
            window._audioConnectionRegistry.connectedElements.set(audioElement, {
              ...existingConnection,
              analyzer: analyzer,
              timestamp: Date.now()
            });
            
            connectionSuccessful = true;
            console.log("Successfully connected to existing MediaElementSource");
          }
        } catch (err) {
          console.warn("Existing connection reuse failed:", err);
          // Continue to next strategy
        }
      }
      
      // STRATEGY 3: Create a non-interfering oscillator as fallback that doesn't block actual audio
      if (!connectionSuccessful) {
        try {
          console.log("Creating oscillator fallback that won't block audio playback");
          
          // Create a silent oscillator
          const oscillator = audioCtx.createOscillator();
          oscillator.frequency.value = 0; // Silent frequency
          
          // Create a gain node to ensure it's silent
          const gainNode = audioCtx.createGain();
          gainNode.gain.value = 0; // Zero gain = silence
          
          // Connect oscillator → gain → analyzer → destination
          oscillator.connect(gainNode);
          gainNode.connect(analyzer);
          analyzer.connect(audioCtx.destination);
          
          // Start the oscillator
          oscillator.start();
          
          // Store cleanup function
          analyzer._cleanup = () => {
            try {
              oscillator.stop();
              oscillator.disconnect();
              gainNode.disconnect();
              analyzer.disconnect();
            } catch (e) {
              // Ignore cleanup errors
            }
          };
          
          // Store the connection but mark it as an oscillator fallback
          window._audioConnectionRegistry.connectedElements.set(audioElement, {
            source: null, // No direct connection to the audio element
            analyzer: analyzer,
            context: audioCtx,
            oscillator: oscillator,
            gain: gainNode,
            isFallback: true,
            timestamp: Date.now()
          });
          
          // Add signal simulation to help visualizer match the audio
          addSignalSimulation(analyzer, audioElement);
          
          connectionSuccessful = true;
          if (typeof window._audioConnectionRegistry.fallbacksCreated === 'number') {
            window._audioConnectionRegistry.fallbacksCreated++;
          } else {
            window._audioConnectionRegistry.fallbacksCreated = 1;
          }
        } catch (err) {
          console.error("Oscillator fallback failed:", err);
        }
      }
      
      // If everything failed, create a pure simulation fallback
      if (!connectionSuccessful) {
        console.warn("All connection strategies failed, using pure simulation");
        return createFallbackAnalyzer(sensitivity, voiceFocus, audioElement);
      }
      
      // Add to global registry for tracking
      if (Array.isArray(window._audioConnectionRegistry.activeAnalyzers)) {
        window._audioConnectionRegistry.activeAnalyzers.push(analyzer);
      } else {
        window._audioConnectionRegistry.activeAnalyzers = [analyzer];
      }
      
      // Store in global state if available for reuse
      if (window.globalAudioState) {
        window.globalAudioState._analyzer = analyzer;
      }
      
      return analyzer;
    } catch (error) {
      console.error("Error creating analyzer:", error);
      if (window._audioConnectionRegistry) {
        window._audioConnectionRegistry.lastError = {
          message: error.message,
          stack: error.stack,
          timestamp: Date.now()
        };
      }
      
      // Fallback to simulation
      return createFallbackAnalyzer(sensitivity, voiceFocus, audioElement);
    }
  }
  
  /**
   * Creates a fallback analyzer that simulates audio levels
   * without actually connecting to audio
   */
  function createFallbackAnalyzer(sensitivity = 1.8, voiceFocus = true, audioElement = null) {
    console.log("Creating pure simulation fallback analyzer");
    
    const fallbackAnalyzer = {
      fftSize: 2048,
      frequencyBinCount: 1024,
      smoothingTimeConstant: 0.75,
      
      // CRITICAL: Add explicit properties for stable access
      sensitivity: sensitivity,
      voiceFocus: voiceFocus,
      
      // Pre-create data arrays
      frequencyDataArray: new Uint8Array(1024),
      timeDataArray: new Uint8Array(1024),
      
      // Time tracking for simulation
      _simulationTime: Date.now() / 1000,
      
      // Main data methods
      getByteFrequencyData: function(dataArray) {
        const t = Date.now() / 1000;
        this._simulationTime = t;
        
        const isPlaying = audioElement ? !audioElement.paused : false;
        
        for (let i = 0; i < dataArray.length; i++) {
          // Create a spectrum shape (more bass, less treble)
          const normalizedIndex = i / dataArray.length;
          const baseCurve = isPlaying ? 
            100 * Math.pow(1 - normalizedIndex, 1.3) : 
            30 * Math.pow(1 - normalizedIndex, 1.1);
            
          // Add time-based modulation
          const mod1 = isPlaying ? 
            30 * Math.sin(t * 2 + i * 0.05) : 
            10 * Math.sin(t * 0.5 + i * 0.05);
            
          const mod2 = isPlaying ? 
            15 * Math.sin(t * 0.7 + i * 0.15) : 
            5 * Math.sin(t * 0.3 + i * 0.1);
            
          const mod3 = isPlaying ? 
            10 * Math.sin(t * 1.3 + i * 0.3) : 
            3 * Math.sin(t * 0.2 + i * 0.05);
            
          // Add subtle noise
          const noise = Math.random() * (isPlaying ? 5 : 2);
          
          // Combine all elements with sensitivity
          const value = (baseCurve + mod1 + mod2 + mod3 + noise) * (this.sensitivity / 1.8);
          
          // Ensure values are within valid range
          dataArray[i] = Math.max(0, Math.min(255, value));
        }
      },
      
      getByteTimeDomainData: function(dataArray) {
        const t = Date.now() / 1000;
        this._simulationTime = t;
        
        const isPlaying = audioElement ? !audioElement.paused : false;
        
        for (let i = 0; i < dataArray.length; i++) {
          const phase = (i / dataArray.length) * Math.PI * 2;
          
          // Base value (128 = center line of waveform)
          const base = 128;
          
          // Primary wave
          const primaryAmp = isPlaying ? 30 : 10;
          const primaryWave = primaryAmp * Math.sin(t * 1.0 + phase);
          
          // Secondary waves for complexity
          const secondaryAmp = isPlaying ? 15 : 5;
          const secondaryWave = secondaryAmp * Math.sin(t * 2.7 + phase * 2.5);
          
          const tertiaryAmp = isPlaying ? 8 : 3;
          const tertiaryWave = tertiaryAmp * Math.sin(t * 5.5 + phase * 4.5);
          
          // Combine waves
          const value = base + primaryWave + secondaryWave + tertiaryWave;
          
          // Ensure values are within valid range
          dataArray[i] = Math.max(0, Math.min(255, value));
        }
      },
      
      // Helper method to get processed audio levels for visualization
      getAudioLevels: function() {
        const t = Date.now() / 1000;
        this._simulationTime = t;
        
        const isPlaying = audioElement ? !audioElement.paused : 
                         (window.globalAudioState ? window.globalAudioState.audioPlaying : false);
        
        // Generate simulated values that look realistic
        const bassVal = isPlaying ? 
          (0.3 + Math.sin(t * 0.6) * 0.15) * this.sensitivity : 
          (0.1 + Math.sin(t * 0.3) * 0.05) * this.sensitivity;
        
        const midVal = isPlaying ? 
          (0.4 + Math.sin(t * 0.8 + 0.5) * 0.2) * this.sensitivity : 
          (0.15 + Math.sin(t * 0.4 + 0.3) * 0.05) * this.sensitivity;
        
        const trebleVal = isPlaying ? 
          (0.25 + Math.sin(t * 0.7 + 0.9) * 0.15) * this.sensitivity : 
          (0.1 + Math.sin(t * 0.35 + 0.7) * 0.05) * this.sensitivity;
        
        // Apply voice focus if requested
        const bass = this.voiceFocus ? bassVal * 1.2 : bassVal;
        const mid = this.voiceFocus ? midVal * 1.5 : midVal;
        const treble = this.voiceFocus ? trebleVal * 0.7 : trebleVal;
        
        return { bass, mid, treble, isSimulated: true };
      },
      
      // Empty cleanup method for consistency
      _cleanup: function() {}
    };
    
    // Track in registry
    if (window._audioConnectionRegistry) {
      if (Array.isArray(window._audioConnectionRegistry.activeAnalyzers)) {
        window._audioConnectionRegistry.activeAnalyzers.push(fallbackAnalyzer);
      } else {
        window._audioConnectionRegistry.activeAnalyzers = [fallbackAnalyzer];
      }
      
      if (typeof window._audioConnectionRegistry.fallbacksCreated === 'number') {
        window._audioConnectionRegistry.fallbacksCreated++;
      } else {
        window._audioConnectionRegistry.fallbacksCreated = 1;
      }
    }
    
    // Store in global state if available
    if (window.globalAudioState) {
      window.globalAudioState._analyzer = fallbackAnalyzer;
    }
    
    return fallbackAnalyzer;
  }
  
  /**
   * Adds smart signal simulation to a real analyzer node
   * to improve fallback cases
   */
  function addSignalSimulation(analyzer, audioElement) {
    if (!analyzer) return;
    
    // Store original methods
    const originalGetByteFrequencyData = analyzer.getByteFrequencyData;
    const originalGetByteTimeDomainData = analyzer.getByteTimeDomainData;
    
    // Override frequency data method
    analyzer.getByteFrequencyData = function(dataArray) {
      // Call original method first
      originalGetByteFrequencyData.call(this, dataArray);
      
      // Check if we got any significant data
      let hasSignificantData = false;
      let sum = 0;
      
      for (let i = 0; i < dataArray.length; i += 10) {
        sum += dataArray[i] || 0;
        if (dataArray[i] > 5) {
          hasSignificantData = true;
        }
      }
      
      const average = sum / (dataArray.length / 10);
      
      // Only add simulation if the data is very quiet or flat
      if (!hasSignificantData || average < 3) {
        const t = Date.now() / 1000;
        const isPlaying = audioElement ? !audioElement.paused : 
                         (window.globalAudioState ? window.globalAudioState.audioPlaying : false);
        
        for (let i = 0; i < dataArray.length; i++) {
          // Create frequency distribution (higher values for lower frequencies)
          const normalizedIndex = i / dataArray.length;
          const baseCurve = isPlaying ? 
            80 * Math.pow(1 - normalizedIndex, 1.3) : 
            20 * Math.pow(1 - normalizedIndex, 1.1);
          
          // Add time-based modulation
          const mod1 = isPlaying ? 
            20 * Math.sin(t * 2 + i * 0.05) : 
            5 * Math.sin(t * 0.5 + i * 0.05);
            
          const mod2 = isPlaying ? 
            10 * Math.sin(t * 0.7 + i * 0.15) : 
            3 * Math.sin(t * 0.3 + i * 0.15);
          
          // Add some randomness
          const noise = Math.random() * (isPlaying ? 5 : 2);
          
          // Combine all factors
          const value = baseCurve + mod1 + mod2 + noise;
          
          // Ensure value is within 0-255 range
          dataArray[i] = Math.max(0, Math.min(255, value));
        }
      }
    };
    
    // Override time domain data method
    analyzer.getByteTimeDomainData = function(dataArray) {
      // Call original method first
      originalGetByteTimeDomainData.call(this, dataArray);
      
      // Check if we got varying data (not just a flat line)
      let hasVariation = false;
      let min = 255, max = 0;
      
      for (let i = 0; i < dataArray.length; i += 10) {
        const value = dataArray[i] || 0;
        min = Math.min(min, value);
        max = Math.max(max, value);
        
        if (Math.abs(value - 128) > 5) {
          hasVariation = true;
        }
      }
      
      // Only add simulation if the data has very little variation
      if (!hasVariation || (max - min < 10)) {
        const t = Date.now() / 1000;
        const isPlaying = audioElement ? !audioElement.paused : 
                         (window.globalAudioState ? window.globalAudioState.audioPlaying : false);
        
        for (let i = 0; i < dataArray.length; i++) {
          // Create phase for sine wave
          const phase = (i / dataArray.length) * Math.PI * 2;
          
          // Center point
          const base = 128;
          
          // Primary wave with amplitude based on playback state
          const primaryAmp = isPlaying ? 30 : 10;
          const primaryWave = primaryAmp * Math.sin(t * 1.5 + phase);
          
          // Secondary waves for complexity
          const secondaryAmp = isPlaying ? 15 : 5;
          const secondaryWave = secondaryAmp * Math.sin(t * 3.7 + phase * 2.5);
          
          const tertiaryAmp = isPlaying ? 8 : 3;
          const tertiaryWave = tertiaryAmp * Math.sin(t * 5.8 + phase * 4.5);
          
          // Combine waves and ensure value is within 0-255 range
          const value = base + primaryWave + secondaryWave + tertiaryWave;
          dataArray[i] = Math.max(0, Math.min(255, value));
        }
      }
    };
  }
  
  /**
   * A utility function to clean up an analyzer and its connections properly
   */
  export function cleanupAnalyzer(analyzer) {
    if (!analyzer) return;
    
    // Clean up resources if cleanup method is available
    if (typeof analyzer._cleanup === 'function') {
      try {
        analyzer._cleanup();
      } catch (e) {
        console.warn("Error during analyzer cleanup:", e);
      }
    }
    
    // Remove from registry
    if (window._audioConnectionRegistry && Array.isArray(window._audioConnectionRegistry.activeAnalyzers)) {
      window._audioConnectionRegistry.activeAnalyzers = 
        window._audioConnectionRegistry.activeAnalyzers.filter(a => a !== analyzer);
    }
  }
  
  /**
   * A hook-style implementation for easier use in React components
   * with automatic cleanup
   */
  export function useAudioAnalyzer(audioElement, options = {}) {
    const { sensitivity = 1.8, voiceFocus = true } = options;
    const analyzerRef = { current: null };
    
    // Initialize analyzer
    if (audioElement && !analyzerRef.current) {
      analyzerRef.current = createRobustAnalyzer(audioElement, { 
        sensitivity, 
        voiceFocus 
      });
    }
    
    // Return cleanup function for React useEffect
    return {
      analyzer: analyzerRef.current,
      cleanup: () => {
        if (analyzerRef.current) {
          cleanupAnalyzer(analyzerRef.current);
          analyzerRef.current = null;
        }
      }
    };
  }
  
  /**
   * Clean up all global analyzer resources
   */
  export function cleanupAllAnalyzers() {
    if (!window._audioConnectionRegistry) return;
    
    // Clean up all active analyzers
    if (Array.isArray(window._audioConnectionRegistry.activeAnalyzers)) {
      window._audioConnectionRegistry.activeAnalyzers.forEach(analyzer => {
        if (analyzer && typeof analyzer._cleanup === 'function') {
          try {
            analyzer._cleanup();
          } catch (e) {
            // Ignore cleanup errors
          }
        }
      });
      
      window._audioConnectionRegistry.activeAnalyzers = [];
    }
    
    // Close all contexts
    if (Array.isArray(window._audioConnectionRegistry.activeContexts)) {
      window._audioConnectionRegistry.activeContexts.forEach(ctx => {
        if (ctx && ctx.state !== 'closed' && typeof ctx.close === 'function') {
          try {
            ctx.close();
          } catch (e) {
            // Ignore close errors
          }
        }
      });
      
      window._audioConnectionRegistry.activeContexts = [];
    }
    
    // Clear connected elements map
    if (window._audioConnectionRegistry.connectedElements instanceof WeakMap) {
      window._audioConnectionRegistry.connectedElements = new WeakMap();
    } else {
      window._audioConnectionRegistry.connectedElements = new WeakMap();
    }
  }
  
  // Helper function to get audio levels - used by external components
  export function getAudioLevels(analyzer, isPlaying = false) {
    if (!analyzer) {
      return simulateAudioLevels(isPlaying);
    }
    
    try {
      // If the analyzer has a getAudioLevels method, use it
      if (typeof analyzer.getAudioLevels === 'function') {
        return analyzer.getAudioLevels();
      }
      
      // Otherwise, calculate levels manually with safety checks
      if (!analyzer.frequencyDataArray) {
        try {
          analyzer.frequencyDataArray = new Uint8Array(analyzer.frequencyBinCount || 1024);
        } catch (err) {
          console.warn("Error creating frequency data array:", err);
          return simulateAudioLevels(isPlaying);
        }
      }
      
      try {
        analyzer.getByteFrequencyData(analyzer.frequencyDataArray);
      } catch (err) {
        console.warn("Error getting frequency data:", err);
        return simulateAudioLevels(isPlaying);
      }
      
      // Calculate frequency bands safely
      let bass = 0, mid = 0, treble = 0;
      let bassCount = 0, midCount = 0, trebleCount = 0;
      const binCount = analyzer.frequencyBinCount || analyzer.frequencyDataArray.length || 1024;
      
      // Low frequencies (bass)
      const bassRange = Math.floor(binCount * 0.15);
      for (let i = 1; i < bassRange; i++) {
        bass += analyzer.frequencyDataArray[i] || 0;
        bassCount++;
      }
      
      // Mid frequencies
      const midRange = Math.floor(binCount * 0.5);
      for (let i = bassRange; i < midRange; i++) {
        mid += analyzer.frequencyDataArray[i] || 0;
        midCount++;
      }
      
      // High frequencies (treble)
      const trebleRange = Math.floor(binCount * 0.8);
      for (let i = midRange; i < trebleRange; i++) {
        treble += analyzer.frequencyDataArray[i] || 0;
        trebleCount++;
      }
      
      // Use a safe sensitivity value with fallback
      const sensitivity = typeof analyzer.sensitivity === 'number' ? 
        analyzer.sensitivity : 1.8;
      
      // Normalize and apply sensitivity safely
      bass = bassCount > 0 ? Math.pow((bass / bassCount) / 255, 0.8) * sensitivity : 0;
      mid = midCount > 0 ? Math.pow((mid / midCount) / 255, 0.7) * sensitivity : 0;
      treble = trebleCount > 0 ? Math.pow((treble / trebleCount) / 255, 0.6) * sensitivity : 0;
      
      // Apply voice focus if needed
      const voiceFocus = typeof analyzer.voiceFocus === 'boolean' ? 
        analyzer.voiceFocus : true;
        
      if (voiceFocus) {
        mid *= 1.5;  // Boost mids for voice
        bass *= 1.2;  // Slightly boost bass too
      }
      
      return { bass, mid, treble, isSimulated: false };
    } catch (err) {
      console.warn("Error getting audio levels:", err);
      return simulateAudioLevels(isPlaying);
    }
  }
  
  // Helper for simulating audio levels when real data isn't available
  function simulateAudioLevels(isPlaying) {
    const t = Date.now() / 1000;
    const sensitivity = 1.8;
    
    // Generate simulated values that look realistic
    const bass = isPlaying ? 
      (0.3 + Math.sin(t * 0.6) * 0.15) * sensitivity : 
      (0.1 + Math.sin(t * 0.3) * 0.05) * sensitivity;
    
    const mid = isPlaying ? 
      (0.4 + Math.sin(t * 0.8 + 0.5) * 0.2) * sensitivity : 
      (0.15 + Math.sin(t * 0.4 + 0.3) * 0.05) * sensitivity;
    
    const treble = isPlaying ? 
      (0.25 + Math.sin(t * 0.7 + 0.9) * 0.15) * sensitivity : 
      (0.1 + Math.sin(t * 0.35 + 0.7) * 0.05) * sensitivity;
    
    return { bass, mid, treble, isSimulated: true };
  }
  
  export default {
    createRobustAnalyzer,
    cleanupAnalyzer,
    useAudioAnalyzer,
    cleanupAllAnalyzers,
    getAudioLevels
  };